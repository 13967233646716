import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UploadStatus } from "common/enum";
import { initialDocumentRequestListState, IDocumentRequestName, ICategory, IDocumentRequest, UploadedFile } from "common/model/document-request-model";

const documentRequestListSlice = createSlice({
    name: 'documentRequestList',
    initialState: initialDocumentRequestListState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setDocumentRequestList(state, action: PayloadAction<{ categorizedDocumentRequestList: ICategory[], isDRLExist: boolean }>) {
            let data = action.payload.categorizedDocumentRequestList;
            let uploadedFiles: IDocumentRequest[] = data.flatMap(document => document.documents);
            state.documentRequestList = data;
            let uploadedDocuments: UploadedFile[] = uploadedFiles.map(doc => ({
                id: doc.id,
                name: doc.fileName,
                status: UploadStatus.Uploaded,
                uploadPercentage: 100,
                documentId: doc.documentId,
                uploadedOn: new Date(),
                file: null,
                taxYear: 0,
                documentGuid: ''
            }));
            state.uploadedDocuments = uploadedDocuments;
            state.isDRLExist = action.payload.isDRLExist;
        },
        setDocumentRequestNames(state, action: PayloadAction<IDocumentRequestName[]>) {
            state.documentRequestNames = action.payload;
        }
    }
})

export const { setIsLoading, setDocumentRequestList, setDocumentRequestNames } = documentRequestListSlice.actions;

export default documentRequestListSlice.reducer;