import { SquarePlusIcon, SquareMinusIcon } from "assets/svg/SVGIconsCollection";
import { FC, useState } from "react";
import PreviewOrClientViewMode from "./PreviewOrClientViewMode";
interface UnRecognizedDocumentsListProps {
    documentsList: IDocument[];
    category: ICategory;
    onPreviewButtonClick: any
    onEditButtonClick: any
    onDeleteButtonClick: (id: number) => void;
    isPreviewOrClientViewMode: boolean;
    isStepCompleted: boolean
}

interface IDocument {
    documentId?: number;
    fileName?: string;
    pageNumber?: number;
    name?: string;
}


interface ICategory {
    categoryId: number;
    categoryName: string;
}

const UnRecognizedDocumentsList: FC<UnRecognizedDocumentsListProps> = ({ documentsList, category, onPreviewButtonClick, onEditButtonClick, onDeleteButtonClick, isPreviewOrClientViewMode, isStepCompleted }) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleCollapsible = () => setIsCollapsed(!isCollapsed)

    return (
        <>
            {documentsList && documentsList.length > 0 ? (

                <>
                    {isCollapsed ? <div className="list-item cursor-pointer" onClick={toggleCollapsible}>
                        
                        <SquarePlusIcon /> 
                        <span className="other-recognized-span">Other Recognized Files</span>
                    </div> :

                        <div className="cursor-pointer" >
                            <div className="other-recognized-container" onClick={toggleCollapsible}>
                                
                                <SquareMinusIcon />  
                                <span className="other-recognized-span">Other Recognized Files</span>
                            </div>
                            <div>
                                {documentsList.map(x => <div className="list-item unrecognized-documents-item">

                                    <span className="drl-label-span ellipsis" title={x.name}>{x.name}</span>

                                    <PreviewOrClientViewMode
                                        isHoveredIconsVisible={true}
                                        document={x}
                                        category={category}
                                        onDeleteButtonClick={onDeleteButtonClick}
                                        isPreviewOrClientViewMode={isPreviewOrClientViewMode}
                                        onEditButtonClick={onEditButtonClick}
                                        onPreviewButtonClick={onPreviewButtonClick}
                                        isStepCompleted={isStepCompleted}
                                    />

                                </div>)}
                            </div>
                        </div>}
                </>
            ) : null}
        </>
    );
};

export default UnRecognizedDocumentsList;