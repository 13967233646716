import React, { useState, useEffect } from 'react';
import { IconSignCompleted } from 'assets/svg/SVGIconsCollection';
import { ELDocType, ElDocument, ELDocLabel, IElDocument } from 'common/model/document';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Guid } from '../helper/guid';

export interface FileListProps {
    selectedDocumentId: number;
    onFileClick(engagementDocument: IElDocument, pageNo: number, isAdditionalDocument: boolean): void;
    selectedPageNo: number,
    elDocuments: IElDocument[];
    documentType: ELDocType
}

const { EL, Additional, Organizer } = ELDocLabel;
const dataTest = "BBF1C5F3-8CA3-4DE7-AA6E-6C4FF6CA46C4";
const parentTreeNode = 'parentTreeNode';
const childTreeNode = 'childTreeNode file-name-sign';
const fileNode = "file-node-";

export const FileList: React.FC<FileListProps> = (props) => {

    const [expanded, setExpanded] = useState(['-1']);
    const [nodes, setNodes] = useState<any[]>([]);

    const getNodeLabel = (lable: string, clasValue: string) => { return <span title={lable} className={clasValue}> {lable} </span> }
    const getPdfIcon = (showCheck: boolean) => { return <i className="fa fa-file-pdf-o" style={{ color: showCheck ? 'green' : '#bbb' }} /> }
    const getSignCompletedIcon = () => { return <IconSignCompleted /> }
    const onExpand = (expanded: any) => setExpanded(expanded);

    const createExpandedNodes = (): any[] => {
        let expandedNodes: string[] = [];
        expandedNodes.push(Additional);
        expandedNodes.push(EL);
        expandedNodes.push(Organizer);
        return expandedNodes;
    }

    const createTreeNodes = (props: FileListProps): any[] => {

        let nodes: any[] = [];
        const engagementLetterDocument = props?.elDocuments?.length > 0 ? props?.elDocuments?.filter(x => x.type == ELDocType.EL)[0] : ElDocument.createNullObject();
        const elSignCompleted = props?.elDocuments?.length > 0 ? props?.elDocuments?.filter(x => x.type == ELDocType.EL)[0]?.signCompleted : false;
        if (engagementLetterDocument?.id > 0) {
            const documentId = engagementLetterDocument.id;
            let tmpNode: any = {
                value: EL,
                label: getNodeLabel(EL, parentTreeNode),
                showcheckbox: false,
                title: EL,
                children: []
            }
            const value = { id: documentId, pageNo: 1, guid: Guid.newGuid() };
            tmpNode.children.push({
                value: JSON.stringify(value),
                label: getNodeLabel(EL, childTreeNode),
                showcheckbox: false,
                icon: elSignCompleted ? getSignCompletedIcon() : getPdfIcon(false),
                title: EL,
                className: fileNode + documentId + "_" + ELDocType.EL + "_1",
                id: fileNode + documentId + "_" + ELDocType.EL + "_1",
            });
            nodes.push(tmpNode);
        }

        const additionalEsign = props?.elDocuments?.filter(x => x.type == ELDocType.Additional);

        if (additionalEsign.length > 0) {
            let tmpNode: any = {
                value: Additional,
                label: getNodeLabel(Additional, parentTreeNode),
                showcheckbox: false,
                title: Additional,
                children: []
            };
            for (let i = 0; i <= additionalEsign.length - 1; i++) {
                const document: IElDocument = additionalEsign[i];
                const signCompleted = props?.elDocuments?.length > 0 ? props?.elDocuments?.find(x => x.id == document.id)?.signCompleted : false;
                const value = { id: document.id, pageNo: 1, guid: Guid.newGuid() };
                const fileName = document?.fileName?.replace(/(.*)\.(.*?)$/, "$1")
                tmpNode.children.push({
                    value: JSON.stringify(value),
                    label: getNodeLabel(fileName ? fileName : "addiional file", childTreeNode),
                    showCheckbox: false,
                    icon: signCompleted ? getSignCompletedIcon() : getPdfIcon(false),
                    className: fileNode + document.id + "_" + ELDocType.Additional + "_1",
                    id: fileNode + document.id +"_" + ELDocType.Additional + "_1",
                });
            }
            nodes.push(tmpNode);
        }

        const organizerWithSignDocument = props?.elDocuments?.length > 0 ? props?.elDocuments?.filter(x => x.type == ELDocType.OrganizerSignDocument)[0] : ElDocument.createNullObject();
        const orgSignCompleted = props?.elDocuments?.length > 0 ? props?.elDocuments?.filter(x => x.type == ELDocType.OrganizerSignDocument)[0]?.signCompleted : false;
        if (organizerWithSignDocument?.id > 0) {
            const documentId = organizerWithSignDocument.id;
            let tmpNode: any = {
                value: Organizer,
                label: getNodeLabel(Organizer, parentTreeNode),
                showCheckbox: false,
                title: Organizer,
                children: []
            }
            const value = { id: documentId, pageNo: 1, guid: Guid.newGuid() };
            tmpNode.children.push({
                value: JSON.stringify(value),
                label: getNodeLabel(Organizer, childTreeNode),
                showCheckbox: false,
                icon: orgSignCompleted ? getSignCompletedIcon() : getPdfIcon(false),
                title: Organizer,
                className: fileNode + documentId + "_" + ELDocType.OrganizerSignDocument + "_1",
                id: fileNode + documentId + "_" + ELDocType.OrganizerSignDocument + "_1",
            });
            nodes.push(tmpNode);
        }
        return nodes;
    }

    const handleFileClick = (node: any) => {
        if (node.children === undefined) {
            const value = JSON.parse(node.value);
            let docType = ELDocType.EL;
            switch (node.parent.title) {
                case EL:
                    docType = ELDocType.EL;
                    break;
                case Additional:
                    docType = ELDocType.Additional;
                    break;
                case Organizer:
                    docType = ELDocType.OrganizerSignDocument;
                    break;
            }
            var selectedDocument = props.elDocuments.filter(x => x.id === parseInt(value.id) && x.type == docType)[0];
            selectedDocument && props.onFileClick(selectedDocument, parseInt(value.pageNo), !(node.parent.title == Organizer || node.parent.title == EL))
        }
    }

    const highlightSelectedFile = (fileId: number, pageNo: number, type: ELDocType) => {
        const currentfileitem: any = document.getElementsByClassName("file-node-selected")[0];
        currentfileitem && currentfileitem.classList.remove("file-node-selected");

        const nextFileitem: any = document.getElementsByClassName(fileNode + fileId + "_" + type + "_" + pageNo)[0];
        nextFileitem && nextFileitem.classList.add("file-node-selected");
    }

    useEffect(() => {
        const expandState = expanded.indexOf("-1") > -1 ? createExpandedNodes() : expanded;
        setExpanded(expandState);
        const nodes = createTreeNodes(props);
        setNodes(nodes);
    }, [props]);


    useEffect(() => {
        highlightSelectedFile(props.selectedDocumentId, props.selectedPageNo, props.documentType);
    }, [props.selectedDocumentId, props.selectedPageNo, nodes]);

    return (
        <div className="file-list-tree-view-container">
            <CheckboxTree
                showExpandAll={false}
                expanded={expanded}
                iconsClass="fa4"
                nodes={nodes}
                expandOnClick
                data-test-auto={dataTest}
                onClick={handleFileClick}
                onExpand={onExpand}
                onlyLeafCheckboxes={true}
            />
        </div>
    );
}