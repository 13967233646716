
export enum ProductTypes {
  General = "General",
  SafeSend_Returns = "SafeSend Returns",
  SafeSend_Signatures = "SafeSend Signatures",
  SafeSend_Organizers = "SafeSend Organizers",
  SafeSend_Gather = "SafeSend Gather",
}

/* PDF Viwer */
export enum ControlRole {
  None = 0,
  Taxpayer = 1,
  Spouse = 2,
  Ero = 3
}
export enum ControlType {
  None = 0,
  Textbox = 1,
  Checkbox = 2,
  Date = 3,
  Dropdown = 4,
  Grid = 5,
  Signature = 6,
  RadioButton = 7,
  RadioButtonGroup = 8,
  QuestionControl = 9,
  Section = 10,
  SignatureCheckBoxControl = 11,
  SignatureRadioButtonControl = 12
}
export enum DataType {
  None = 0,
  AlphaNumeric = 1,
  Numeric = 2,
  SSN = 3,
  TextArea = 4,
  Label = 5,
  Amount = 6,
  Name = 7,
  Initial = 8,
  Email = 9,
  Text = 10
}

export enum GroupType {
  None = 0,
  Organizer = 1,
  Engagement = 2,
  Removed = 3,
  customQuestion = 4,
  AdditionalEFile = 5,
  OrganizerWithSignature = 6,
}
/*=============================== */


/* PDF Viewer Model*/
export enum AccordianItemType {
  Bookmark = 0,
  EngagementLetter = 1,
  AdditionalDocuments = 2,
  ConsentForm = 3,
  Deleted = 4,
  Others = 5,
}
export interface FormGroup {
  id: number;
  gatherId: number;
  name: string;
  type: GroupType;
  status: FormGroupStatus;
  uploadedBy?: number;
  uploadedOn?: Date;
  remarks: string;
  orderInFile: number;
  forms: Form[];
}
export enum PdfPageOrientation {
  Portrait = 0,
  Landscape = 1
}
export interface IFormData {

}
export interface Form {
  formId: number;
  formGuid: string;
  formGroupId: number;
  formType: FormType;
  pageNo: number;
  bookmark: string;
  formName: string;
  individualFilePageNo?: number;
  formData: IFormData;
  message: string;
  enabledSourceDocument: boolean;
  enableSignature: boolean;
  rotationDegree: number;
  orientation?: PdfPageOrientation;
}
export enum FormType {
  None = 0,
  OrganizerClient = 1,
  Organizer = 2,
  EngagementLetter = 3,
  Removed = 4,
  AdditionalQuestions = 5,
  OrganizerWithSign = 6,
}
export interface OrganizerForm extends Form {
  enabledSourceDocument: boolean;
  formData: OrganizerFormData;
}

export interface ControlValue {
  formGuid: string;
  controlGuid: string;
  value: string;
  filledBy: ClientType;
}
export interface BlobForm {
  formGuid: string;
  controlList: Control[];
}

export interface EngagementLetterForm extends Form {
  formData: EngagementLetterFormData;
}

export interface EngagementLetterFormData {
  controlValues: Control[];
}

export interface BoundingRectangle {
  left: number;
  top: number;
  width: number;
  height: number;
}

export interface Control {
  id: string;
  signerId: number;
  name: string;
  controlType: ControlType;
  dataType: DataType;
  tooltip: string;
  readonly: boolean;
  required: boolean;
  boundingRectangle: BoundingRectangle;
  customData: string;
  controlRole: ControlRole;
}
export interface OrganizerForm extends Form {
  enabledSourceDocument: boolean;
  formData: OrganizerFormData;
}

export interface OrganizerFormData {
  controlValues: ControlValue[];
}

export interface BookmarkPage {
  pageNo: number;
  pageTitle: string;
}

export interface Bookmarks {
  heading: string;
  pages: BookmarkPage[];
}

export interface OrganizerBookmarks {
  heading: string;
  pages: OrganizerBookmarkPage[];
}

export interface OrganizerBookmarkPage extends BookmarkPage {
  enabledSourceDocument: boolean;
  message: string;

}
export interface SignatureControlsDictionary {
  [pageNo: number]: Control[] | ControlValue[];
}

export interface PdfPageSignatureControl {
  signatureControls: Control[];
  signerId: number;
  focusGroup: UserGroup;
}
export enum UserGroup {
  None,
  Sender,
  Reciever
}


export interface TextboxData {
  value: string;
}

export interface Signature extends Control {
  controlData: SignatureData;
}

export interface SignatureData {
  name: string;
  fileGuid: string;
}

export interface DateControl extends Control {
  placeholder: string;
  textPlacement: TextPlacement;
  controlData: DateControlData;
}

export interface DateControlData {
  value: Date;
}

export enum TextPlacement {
  Left = 1,
  Right = 2,
  Center = 3
}

export const initialControl: Control = {
  controlType: ControlType.None,
  id: "",
  name: '',
  signerId: 0,
  dataType: DataType.None,
  tooltip: '',
  readonly: false,
  required: false,
  boundingRectangle: {
    left: 0,
    top: 0,
    width: 0,
    height: 0
  } as BoundingRectangle,
  customData: '',
  controlRole: ControlRole.None
}

export interface RadioButton extends Control {
  controlData: RadioButtonControlData;
}

export interface RadioButtonControlData {
  selected: boolean;
}

export interface RadioButtonGroup extends Control {
  controlData: RadioButtonGroupControlData;
}

export interface RadioButtonGroupControlData {
  items: RadioButton[];
  selectedId: string;
}

export interface TextboxData {
  value: string;
}

export interface Textbox extends Control {
  maxLength: number;
  placeholder: string;
  textPlacement: TextPlacement;
  controlData: TextboxData;
}
export enum QuestionType {
  PARAGRAPH = 1,
  YESNO,
  MULTICHOICE
}
export interface QuestionControl extends Control {
  questionNo: string;
  textbox: Textbox;
  radioButtonGroup: RadioButtonGroup;
  sectionId: string,
  subQuestions: SubQuestionMapping[],
  parentQuestionId: string,
  type: QuestionType;
  required: boolean;
  inputTextbox: Textbox;
}

export interface SectionControl extends Control {
  section: Textbox;
}

export interface SubQuestionMapping {
  subQuestionIds: string[];
  condition: boolean;
}
export interface SignatureCheckBoxControl extends Control {
  page: number;
  disabled: boolean;
  items: ChoosableControlData[];
}

export interface SignatureRadioButtonControl extends Control {
  page: number;
  disabled: boolean;
  items: ChoosableControlData[];
}

export class ChoosableControlData {

  id: string = '';
  name: string = '';
  top: number = 0;
  left: number = 0;
  width: number = 0;
  height: number = 0;
  value: any;

  public static create(
    id: string,
    name: string,
    top: number,
    left: number,
    width: number,
    height: number,
    value: any
  ) {

    var data = new ChoosableControlData();
    data.id = id;
    data.name = name;
    data.top = top;
    data.left = left;
    data.width = width;
    data.height = height;
    data.value = value;

    return data;
  }

  public static createNullObject() {

    var data = new ChoosableControlData();
    data.id = "";
    data.name = "";
    data.top = 0;
    data.left = 0;
    data.width = 0;
    data.height = 0;
    data.value = { checked: false, value: '' };
    return data;
  }
}

/*================================================== */

export enum HeaderType {
  BLUE = 0,
  BICOLOR,
  WITHSTEP
}

export const enum StepStatus {
  DONE = 0,
  PENDING,
  NEW
};
export interface IStep {
  title: string;
  status: number;
  action: number;
}

export const enum StepActionButton {
  NONE = 0,
  DOWNLOAD,
  VIEW
};

export enum ClientType {
  Undefined = 0,
  Taxpayer,
  Spouse,
  Partner,
  Partnership,
  Shareholder
}

export enum DocumentStatus {
  None = 0,
  UPLOADED,
  ERROR,
  READY,
  PROCESSING,
  REVIEW,
  USERSIGNED,
  DELIVERED,
  APPROVEDFORDELIVERY,
  PREPARINGFORDELIVERY,
  DELIVERYFAILED,
  NONSUPPORTED,
  RECALLED,
  DELETED
}

export enum Role {
  None = 0,
  Taxpayer,
  Spouse,
  Partner,
  Partnership,
  Shareholder,
  CPA
}

export enum StatusType {
  None,
  Error,
  Success,
  Warning,
};

export enum ErrorStatus {
  None = 0,
  InvalidLink = 1,
  OrganizerDeleted = 2,
  ClosedByFirm = 3,
}

export enum StatusCodes {
  Conflict = 409,
  NotFound = 404,
  Gone = 410,
  Ok = 200
}

export enum SourceDocumentStatus {
  None = 0,
  AwaitingUpload = 1,
  Uploaded = 2,
  Downloaded = 3,
  Completed = 4
}

export enum UploadStatus {
  Wait = 0,
  Initiating = 1,
  Uploading = 2,
  Uploaded = 3,
  Error = 4,
  Converting = 5
}

export enum EngagementType {
  None,
  E1040 = 1,
  E1065 = 2,
  E1120 = 3,
  E1120S = 4,
  E1041 = 5,
  E1040NR = 6
}

export enum SignatureType {
  None,
  ESign = 1,
  ESignWhenAllIncludedReturnsAllowed = 2,
  ManualSign = 3
}

export enum SignatureStatus {
  None = 0,
  ESigned = 1,
  ManuallySigned = 2,
  Uploaded = 3,
  AwaitingESign = 4,
  AwaitingUpload = 5,
  Locked = 6,
  MailOrFax = 7,
  SignedAndESigned = 8,
  AutoParsed = 9,
  Processing = 10,
  Delivering = 11,
  Delivered = 12,
  DeliveryFailed = 13
}

export enum OtpMode {
  None = 0,
  Email = 1,
  Text = 2
}

export enum FormGroupStatus {
  None = 0,

  // organizer
  Delievered = 1,
  PartiallyCompleted = 2,
  Completed = 3,
  Downloaded = 4,
  Dropped = 5,
  ManuallyCompleted = 6,
  QuestionnaireCompleted=8,

    // engagement 
    AwaitingESign = 21,
    PartiallySigned = 22,
    ESigned = 23,
    NA = 25,
    Reviewed = 26,
    Declined = 27,
    ManuallySigned = 28,
    PartiallyReviewed = 29,
}

export enum Action
{
    None = 0,
    Download = 1,
    View = 2
} 

export enum GatherStepStatus
{
    None = 0,
    Pending = 1,
    Done = 2
}

export enum NotificationType
{
  None = 0,
  DeliverySuccess = 1,
  DeliveryFailed = 2,
  RecognizerCompleted = 3,
  RecognizerFailed = 4,
  Esigned = 5,
  PartiallySigned = 6,
  OrganizerCompleted = 7,
  OrganizerPartiallyCompleted = 8,
  PartiallyReviewed = 9,
  Reviewed = 10,
  QuestionnaireCompleted = 11,
  SourceDocumentUploaded = 12,
  SourceDocumentCompleted = 13,
  SourceDocumentDownloaded = 14,
  ClosedByFirm = 15,
  GatherDeleted = 16,
  BatchProcessingSuccess = 17,
  BatchProcessingPartiallySuccess = 18,
  BatchProcessingFailed = 19,
  BulkDownloadSuccess = 20,
  BulkDownloadFailed = 21,
  ArchiveGathersSuccess = 22,
  ArchiveGathersFailed = 23,
  RecycleGathersSuccess = 24,
  RecycleGathersFailed = 25,
  PermanentDeleteGathersuccess = 26,
  PermanentDeleteGatherFailed = 27,
  DocumentFormTypeMappingSuccess = 28,
  DocumentFormTypeMappingFailed = 29,
  ManuallyStatusUpdated = 30,
  DRLNotApplicableStatusUpdated = 34,
  SourceDocumentAwaitingUpload = 36,
  DrlPredictionSuccess = 37,
  DrlPredictionFailed = 38
}

export enum PreviewStatus
{
    Unknown = 0,
    Preparing = 1,
    Ready = 2,
    Error = 3
}

export enum AuthenticationOption  {
  None= 0,
  Text= 1,
  Email= 2
};

export enum SourceDocumentAIStatus
{
    None = 0,
    InProgress = 1,
    Success = 2,
    Failed = 3
}

export enum DRLFileType {
  Recognized, Unrecognized
}

export enum DRLPredictionStatus{
  None = 0,
  InProgress = 1,
  Success = 2,
  Failed = 3
}
