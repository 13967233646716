import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { useAppSelector } from 'common/hooks/redux-hooks';
import { IDocumentRequestName } from 'common/model/document-request-model';

interface IDRLDropdownProps {
    fileId: number;
    checkedItems: Record<string, boolean>;
    selectedRadio: string;
    setCheckedItems: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    setSelectedRadio: React.Dispatch<React.SetStateAction<string>>;
}

const DRLDropdown: React.FC<IDRLDropdownProps> = ({ fileId, checkedItems, selectedRadio, setCheckedItems, setSelectedRadio }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const { documentRequestNames, documentRequestList } = useAppSelector(state => state.documentRequestReducer);

    useEffect(() => {
        documentRequestList.forEach((document: any) => {
            if (document.categoryName === "Other") {
                document.documents.forEach((element: any) => {
                    element.sourceDocuments.forEach((sourceDocument: any) => {
                        if (sourceDocument.documentId === fileId) {
                            setSelectedRadio(element.id.toString());
                        }
                    })
                })
            }
            else {
                document.documents.forEach((element: any) => {
                    element.sourceDocuments.forEach((sourceDocument: any) => {
                        if (sourceDocument.documentId === fileId) {
                            !checkedItems[element.id] && element.id && setCheckedItems((prev) => {
                                return { ...prev, [element.id]: true }
                            });
                        }
                    })
                })
            }
        })
    }, [fileId]);

    const toggleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const newCheckedItems = {
            ...checkedItems,
            [name]: checked,
        };

        // If at least one checkbox is checked, clear the selected radio
        if (Object.values(newCheckedItems).some(Boolean)) {
            setSelectedRadio('');
        }

        setCheckedItems(newCheckedItems);
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        // When a radio is selected, uncheck all checkboxes
        if (value !== selectedRadio) {
            setCheckedItems({});
        }

        setSelectedRadio(value);
    };

    const handleToggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    // Determine the title for the dropdown button
    const getDropdownTitle = () => {
        const selectedCount = Object.values(checkedItems).filter(Boolean).length;
        if (selectedCount > 0) {
            return `${selectedCount} selected`;
        } else if (selectedRadio) {
            return "1 selected";
        } else {
            return "Select...";
        }
    }

    return (
        <Dropdown show={isDropdownOpen} onToggle={handleToggleDropdown}>
            <DropdownButton
                variant="outline-secondary"
                id="multi-select-drl-dropdown"
                title={getDropdownTitle()}
                onClick={handleToggleDropdown}
            >
                <div className="padding-left-right-10-px">
                    {documentRequestNames.filter((file: IDocumentRequestName) => file.categoryName !== "Other").map((file: IDocumentRequestName, index: number) =>
                        <Form.Check
                            key={file.id}
                            type="checkbox"
                            id={file.id.toString()}
                            label={<span className='ellipsis' title={file.name}>{file.name}</span>}
                            name={file.id.toString()}
                            checked={!!checkedItems[file.id]}
                            onChange={toggleCheckbox}
                        />
                    )}
                    {documentRequestNames.filter((file: IDocumentRequestName) => file.categoryName === "Other").map((file: IDocumentRequestName, index: number) => {
                        return (
                            <>
                                {index === 0 && <hr className='margin-top-bottom-10-px' />}
                                <Form.Check
                                    key={file.id}
                                    type="radio"
                                    id={`${file.id}`}
                                    label={<span className='ellipsis' title={file.name}>{file.name}</span>}
                                    name="radioOptions"
                                    value={file.id.toString()}
                                    checked={selectedRadio === file.id.toString()}
                                    onChange={handleRadioChange}
                                />
                            </>
                        )
                    })}
                </div>
            </DropdownButton>
        </Dropdown>
    );
};

export default DRLDropdown;
