import { SquarePlusIcon, SquareMinusIcon } from "assets/svg/SVGIconsCollection";
import { FC, useState } from "react";
import PreviewOrClientViewMode from "./PreviewOrClientViewMode";
import {
    RadioButtonIcon,
    RadioButtonTickedIcon

  } from "../../assets/svg/SVGIconsCollection";
import { ButtonComponent } from "cp-common-ui-components";
import { GatherUploadedDocuments } from "helper/Constants";

interface DocumentsListProps {
    title: string;
    documentsList: IDocument[];
    isMapped: boolean;
    category: ICategory;
    onPreviewButtonClick: any
    onEditButtonClick: any
    onDeleteButtonClick: (id: number) => void;
    isPreviewOrClientViewMode: boolean;
    isStepCompleted: boolean;
    notApplicable?: boolean;
    id?: number;
    undoNotApplicableClicked?: (id: number) => void;
    notApplicableClicked?: (id: number) => void;
}

interface IDocument {
    documentId?: number;
    pageNumber?: number;
    fileName?: string;
}

interface ICategory {
    categoryId: number;
    categoryName: string;
}

const CollapsibleDRLList: FC<DocumentsListProps> = ({ 
    id, title, documentsList, category, onPreviewButtonClick, 
    onEditButtonClick, onDeleteButtonClick, isPreviewOrClientViewMode, 
    isStepCompleted, isMapped, notApplicable,
    undoNotApplicableClicked, notApplicableClicked
}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleCollapsible = () => setIsCollapsed(prev => !prev);

    const { categoryName } = category;

    if (!id) return null;

    return (
        <>
        {categoryName !== 'Other' ? (
          <div className="drl-tree">
            {isMapped ? (
              <>
                <div className="list-item">
                  <RadioButtonTickedIcon />
                  <span className="cursor-pointer" onClick={toggleCollapsible}>
                    {isCollapsed ? <SquarePlusIcon /> : <SquareMinusIcon />}
                  </span>
                  <span className="drl-label-span" title={title}>{title}</span>
                  <span className="mapped-file-count">+ {documentsList.length}</span>
                </div>
                
                {!isCollapsed && (
                  <div className="expanded-list-items">
                    {documentsList.map((x: any, idx: number) => (
                      <div key={idx} className="list-item unrecognized-documents-item">
                        <span className="drl-label-span" title={x.fileName}>
                          {x.fileName}
                        </span>
                        <PreviewOrClientViewMode
                          isHoveredIconsVisible={true}
                          document={x}
                          category={category}
                          onDeleteButtonClick={onDeleteButtonClick}
                          isPreviewOrClientViewMode={isPreviewOrClientViewMode}
                          onEditButtonClick={onEditButtonClick}
                          onPreviewButtonClick={onPreviewButtonClick}
                          isStepCompleted={isStepCompleted}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <div className="list-item">
                <RadioButtonIcon />
                <span className="drl-label-span" title={title}>{title}</span>
                {notApplicable ? (
                  <ButtonComponent
                    className="NotApplicable"
                    title={GatherUploadedDocuments.UndoNAToolTip}
                    onClick={() => id && undoNotApplicableClicked && undoNotApplicableClicked(id)}
                    disabled={isStepCompleted || isPreviewOrClientViewMode ? true : false}
                  >
                    {GatherUploadedDocuments.UndoNotApplicable}
                  </ButtonComponent>
                ) : (
                  <ButtonComponent
                    className="NotApplicable"
                    title={GatherUploadedDocuments.NAToolTip}
                    onClick={() => id && notApplicableClicked && notApplicableClicked(id)}
                    disabled={isStepCompleted || isPreviewOrClientViewMode ? true : false}
                  >
                    {GatherUploadedDocuments.NotApplicable}
                  </ButtonComponent>
                )}
              </div>
            )}
          </div>
        ) : (
            <div>
                {documentsList.map((x: any, idx: number) => (
                    <div key={idx} className="list-item">
                        {isMapped ? <RadioButtonTickedIcon /> : <RadioButtonIcon/>}
                        <span className="drl-label-span" title={title}>
                            {title}
                        </span>
                        <PreviewOrClientViewMode
                            isHoveredIconsVisible={true}
                            document={x}
                            category={category}
                            onDeleteButtonClick={onDeleteButtonClick}
                            isPreviewOrClientViewMode={isPreviewOrClientViewMode}
                            onEditButtonClick={onEditButtonClick}
                            onPreviewButtonClick={onPreviewButtonClick}
                            isStepCompleted={isStepCompleted}
                        />
                    </div>
                ))}
             </div>
        )}
      </>
    );
};

export default CollapsibleDRLList;