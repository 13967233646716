import { StatusType } from "common/enum";
import { ThunkAction } from "redux-thunk";
import { AppState } from "store";
import { Action } from "@reduxjs/toolkit";
import Axios from "services/api/axios-interceptor"
import { API } from "services/api/api-config";
import { setNotification } from "store/slices/notification";
import { OtpPageConstants } from "helper/Constants";
import { setLoader } from "store/slices/loader";
import { GatherCompanySettingsModel, IUteSettings } from "common/model/company-settings";
import { setCompanySettingData } from "store/slices/company-settings-slice";

export const getGatherCompanySettingsModel = (clientId: string, callback?: (data: GatherCompanySettingsModel) => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(setLoader(true));
        Axios.get(`${API.COMPANY_SETTINGS.GET_COMPANY_SETTINGS}/${clientId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => {
                const settingsData: GatherCompanySettingsModel = response as GatherCompanySettingsModel;
                dispatch(setCompanySettingData(settingsData));
                callback && callback(settingsData);
                dispatch(setLoader(false));
            }).catch(() => {
                dispatch(setNotification({
                    type: StatusType.Error,
                    message: OtpPageConstants.serverError
                }))
                dispatch(setLoader(false));
            })
    }

export const getOneHubRedirectUrl = (clientId: string, callback?: (url: string) => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(setLoader(true));
        Axios.get(`${API.ONEHUB.GET_ONEHUB_REDIRECT_URL}/${clientId}`, {
            credentials: 'include'
        })
            .then(response => {
                if(callback) callback(response);
                dispatch(setLoader(false))
            }).catch(() => {
                dispatch(setNotification({
                    type: StatusType.Error,
                    message: OtpPageConstants.serverError
                }))
                dispatch(setLoader(false));
            })
    }

export const getUTESettings = (clientId: string, callback?: (data: IUteSettings) => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(setLoader(true));
        Axios.get(`${API.COMPANY_SETTINGS.GET_UTE_SETTINGS}/${clientId}`, {
            credentials: 'include'
        })
            .then(response => {
                if(callback) callback(response);
                dispatch(setLoader(false))
            }).catch(() => {
                dispatch(setNotification({
                    type: StatusType.Error,
                    message: OtpPageConstants.serverError
                }))
                dispatch(setLoader(false));
            })
    }

export const getOneHubRedirectClientViewUrl = (clientId: string, callback?: (url: string) => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(setLoader(true));
        Axios.get(`${API.ONEHUB.GET_ONEHUB_REDIRECT_CLIENTVIEW_URL}/${clientId}`, {
            credentials: 'include'
        })
            .then(response => {
                if (callback) callback(response);
                dispatch(setLoader(false))
            }).catch(() => {
                dispatch(setNotification({
                    type: StatusType.Error,
                    message: OtpPageConstants.serverError
                }))
                dispatch(setLoader(false));
            });
    }