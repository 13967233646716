
import { FC, useEffect, useState, } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ProgressBar } from 'react-bootstrap';
import FileUpload from 'common/components/file-upload/FileUpload';
import { AlertIcon, AttachmentIcon } from 'assets/svg/SVGIconsCollection';
import { ButtonConstant, OrganizerConstant, GatherUploadedDocuments, sourceDocumentsUploadOrgWarningMessage } from 'helper/Constants';
import { CustomModal } from 'common/components/UIComponents/CustomModal';
import { ConvertedUploadedFileModel, UploadedDocument, UploadedFile } from 'common/model/document-request-model';
import { convertToUploadedFileModel, handleFilesWithZeroSize } from 'helper/UploadDocumentHelper';
import { Toaster } from 'common/components/Toasts';
import { DRLPredictionStatus, UploadStatus } from 'common/enum';
import { addUploadedDocument, getUploadLink as getDocumentUploadLink, } from "../../store/services/sourceDocumentUpload";
import { FileUploadUtilities, IFileUploadUtilities } from 'core/utilities/FileUploadUtilities';
import { getFileSize } from 'helper/HelperFunctions';
import { useAppDispatch } from 'common/hooks/redux-hooks';
import { useClientId } from 'common/hooks/client-hook';
import "./index.scss";
import { DRLPredictionRequest, IDRLPredictionRequest } from 'common/model/document-drl-prediction';
import { addDocumentGuidToDRLPredictionRequest, setDRLPredictionDetail } from 'store/slices/drlPrediction-detail-slice';

interface UploadOrganizerModalProps {
  onHide: () => void,
  onSubmit: () => void,
  show: boolean,
  setShowModal: (value: boolean) => void,
  getUploadedFile : ()=>void,
  currentForm:any
}
const fileUploadUtilities: IFileUploadUtilities = new FileUploadUtilities();

const FileUploadModal: FC<UploadOrganizerModalProps> = ({ onHide, show, setShowModal,getUploadedFile , currentForm }) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [uploadedFileData, setUploadedFileData] = useState<any>()
  const [disableConfirm, setDisableConfirm] = useState<boolean>(false)
  const [drlPredictionRequest, setDRLPredictionRequest] = useState<IDRLPredictionRequest>(new DRLPredictionRequest(-1, ""));

  const dispatch = useAppDispatch();

  const [clientId] = useClientId();

  useEffect(() => {
    if (!show) {
      setTableData([]);
      setDisableConfirm(true)
    }
  }, [show]);

  useEffect(()=>{
    tableData.forEach(file=>{
      if(file.uploadPercentage<100 || tableData.length===0)
      {
        setDisableConfirm(true)
      }
      else{
        setDisableConfirm(false)
      }
    })

  },[tableData])

  const getUploadLink = (files: UploadedFile[]) => {
    setTableData([...tableData, ...files])
    let drlPredictionRequest: IDRLPredictionRequest = new DRLPredictionRequest(-1, clientId);
    setDRLPredictionRequest(drlPredictionRequest);
    dispatch(setDRLPredictionDetail(drlPredictionRequest));
    files.filter((x: UploadedFile) => x.status === UploadStatus.Wait).forEach((file: UploadedFile, index: number) => {
      file.status = UploadStatus.Initiating;
      file.name = file.name.trim();
      dispatch(getDocumentUploadLink(clientId, file, drlPredictionRequest.requestId, onGetDocumentUploadLinkSuccess));
    })
  }

  const handleDeleteClick = (row: any) => {
    if (tableData?.length > 0) {
      let indexToRemove: number = tableData.findIndex(
        (item: any) => {
          return item.id === row.id;
        });
      const newTableData = [...tableData];
      newTableData.splice(indexToRemove, 1);
      setTableData(newTableData);
    }
  }

  const handleAddDocument = (files: UploadedFile[],currentForm:any) => {
    files.forEach((file) => {
      let uploadedDocument: UploadedDocument = UploadedDocument.create(file.name, getFileSize(file.file.size),currentForm?.formId, file.documentGuid);
      let drlPredictionRequestId = drlPredictionRequest.requestId;
      dispatch(addUploadedDocument(
        clientId,
        uploadedDocument,
        () => {
          getUploadedFile()
          AddDocumentGuidToDRLPredictionRequest(drlPredictionRequestId, uploadedDocument.documentGuid);
        },
        () => { }
      ))
      setShowModal(false);
    })
  }

  const AddDocumentGuidToDRLPredictionRequest = (drlPredictionRequestId: string, documentGuid: string) => {
    dispatch(addDocumentGuidToDRLPredictionRequest({ requestId: drlPredictionRequestId, documentGuid: documentGuid, status: DRLPredictionStatus.InProgress }));
  }

  const onGetDocumentUploadLinkSuccess = (data: any, file: UploadedFile) => {
    fileUploadUtilities.upload(
      file.file,
      data.sas,
      file.name ? file.name : "",
      uploadProgress,
      );
  }

  const uploadProgress = (progress: number, fileUploaded: any) => {
    setTableData((prevFiles) => prevFiles.map((file: UploadedFile) => file.name === fileUploaded.fileName ? { ...file, uploadPercentage: progress } : file))
    setUploadedFileData({ ...uploadedFileData, uploadPercentage: progress })
  }

  const handleFileUpload = (files: any) => {
    let convertedUploadedFileModel: ConvertedUploadedFileModel = convertToUploadedFileModel(files);
    if (convertedUploadedFileModel.isFileSizeLimitExceeded) {
      Toaster.warn(sourceDocumentsUploadOrgWarningMessage(
        convertedUploadedFileModel.counterFileSizeLimitFailed,
        GatherUploadedDocuments.SupportedUploadFileSizeWarningMessage));

    }
    if (convertedUploadedFileModel.isUnsupportedFileExtension) {
      Toaster.warn(sourceDocumentsUploadOrgWarningMessage(
        convertedUploadedFileModel.counterUnsupportedFileExtensionFailed,
        GatherUploadedDocuments.SupportedFilesMessage));
    }

    handleFilesWithZeroSize(convertedUploadedFileModel.uploadedFiles).then((uploadFilesWithValidData) => {
      if (uploadFilesWithValidData.length !== convertedUploadedFileModel.uploadedFiles.length) {
        Toaster.warn(GatherUploadedDocuments.SupportedFilesSizeMessage);
      }
      if (uploadFilesWithValidData.length > 0) {
        getUploadLink(uploadFilesWithValidData)
      }
    });
  }

  const actionFormatter = (cell: any, row: any) => {
    return <button className='btn-without-border'
      onClick={() => handleDeleteClick(row)}>{ButtonConstant.Delete}</button>

  };

  const sizeFormatter = (cell: any, row: any) => {
    return <div className='file-name'>
      <label title={row.file.size} className="ellipsis">
        {getFileSize(row.file.size)}
      </label>
    </div>
  }

  const nameFormatter = (cell: any, row: any) => {
    return <div className='file-name'>
      <div>
        <AttachmentIcon />
      </div>
      <label title={row.name} className="ellipsis">
        {row.name}
      </label>
    </div>
  };

  const progressFormatter = (cell: any, row: any) => {
    return <div className='upload-progress-container'>
      <ProgressBar 
        now={row.uploadPercentage}
        className='upload-progress-custom'
      />
    </div>
  };

  const columns = [
    {
      header: "Name",
      key: "name",
      isKey: true,
      className: "text-field-header header-text",
      width: "160px",
      dataFormat: nameFormatter,
      columnClassName: "column-padding-left"
    },
    {
      header: "Upload Progress",
      key: "uploadProgress",
      isKey: false,
      className: "text-field-header header-text",
      width: "200px",
      dataFormat: progressFormatter,
      columnClassName: "column-padding-left"
    },
    {
      header: "Size",
      key: "size",
      isKey: false,
      className: "text-field-header header-text",
      width: "70px",
      dataFormat: sizeFormatter,
      columnClassName: "column-padding-left"
    },
    {
      header: "Actions",
      key: "actions",
      isKey: false,
      className: "text-field-header header-text",
      width: "90px",
      dataFormat: actionFormatter,
      columnClassName: ""
    }];

  return (<>
    <CustomModal show={show}
      className='upload-organizer-file-modal'
      size="xl"
      title='Add Source Documents'
      onHide={() => {
        setShowModal(false);
        onHide();
      }}
      onSubmit={() => { handleAddDocument(tableData,currentForm) }}
      disableConfirmButton = {disableConfirm}
    >
      <div className='upload-organizer-container'>
        <div className='info-container'>
          <div><AlertIcon className='alert-icon'/></div>
          <p className='message'><b>{OrganizerConstant.ImportantNote}</b> {OrganizerConstant.DoNotUpload}</p>
        </div>
        <div className='content'>
          <FileUpload isIncludeHint={true} className='organizer-upload'
            onUpload={handleFileUpload}
            allowedFileFormats={['pdf']} />

          <div className='right-container'>
            <div className='table-container'>
              <BootstrapTable
                data={tableData}
                headerContainerClass="sticky-header"
                containerClass="table-container"
                bordered={false}
                trStyle={{ height: "32px" }}
              >
                {columns.map((value, index) => {
                  return (
                    <TableHeaderColumn
                      key={index}
                      isKey={value.isKey}
                      dataField={value.key}
                      width={value.width}
                      dataFormat={value.dataFormat}
                      columnClassName={value.columnClassName}
                    >
                      <span title={value.header} className="table-text-sort">
                        {value.header}
                      </span>
                    </TableHeaderColumn>
                  );
                })}
              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>

    </CustomModal>
  </>
  );
}
export default FileUploadModal;