import { FC, useEffect, useRef, useState } from 'react';
import { PdfViewer } from 'awesome-pdf-viewer';
import { Header, } from 'awesome-pdf-viewer/dist/layout/Header';
import { LeftPanel } from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import { RightPanel } from 'awesome-pdf-viewer/dist/layout/RightPanel';
import { SignatureControl, SignatureControlProps } from 'awesome-pdf-viewer/dist/Controls/SignatureControl';
import { CheckBoxControl } from 'awesome-pdf-viewer/dist/Controls/CheckBox/CheckBoxControl';
import DatePickerControl from 'awesome-pdf-viewer/dist/Controls/DatePickerControl/DatePickerControl';
import { NumericTextboxControl } from 'awesome-pdf-viewer/dist/Controls/NumericTextboxControl';
import { LabelControl } from 'awesome-pdf-viewer/dist/Controls/LabelControl';
import { TextBoxControl } from 'awesome-pdf-viewer/dist/Controls/TextBoxControl';
import { DropdownControl } from 'awesome-pdf-viewer/dist/Controls/DropdownControl/DropdownControl';
import { RadioButtonControl } from 'awesome-pdf-viewer/dist/Controls/RadioButtonControl/RadioButtonControl';
import { Pagination } from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import { Toolbar } from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { CustomOptions } from 'awesome-pdf-viewer/dist/toolbar/CustomOptions';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import { PageMode, PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { PageProperties, PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import ControlBase, { CheckBoxData, DateData, DropdownData, LabelData, PDF_VIEWER_BASE_HEIGHT, PDF_VIEWER_BASE_WIDTH, RadioButton, RadioButtonData, SignatureData, TextBoxData, TextBoxTypes } from 'awesome-pdf-viewer/dist/Controls/ControlBase'
import { compareFormControl, getCheckBoxClassName, getControlText, getDatePickerClassName, getEsignBookmarks, getOrganizerBookmarks } from './helper/helper';
import { BlobForm, ClientType, Control, ControlType, ControlValue, EngagementLetterForm, Form, FormGroup, FormType, OrganizerForm, OrganizerFormData, SignatureControlsDictionary } from '../../enum/index';
import { DataType, GroupType } from '../../enum/index';
import RightPanelContent from './components/RightPanelContent';
import BookmarkPanelConent from './components/BookmarkPanelContent';
import './AwesomePdfViewer.scss';
import { SignatureControlConstants } from 'helper/Constants';
import moment from 'moment';


export type TooltipData = {
    toolTipControl: Control;
    showToolTipPopup: boolean;
    signatureControlType: number,
    signatureDataType: DataType,
    content: {
        header: string,
        title: string,
        tooltipContent: string
    }
}

export interface AwesomePdfViewerProps {
    documentUrl: string;
    leftPanelTabs?: any,
    isSourceDocumentEnabled: boolean;
    setCurrentForm?: (form: any) => void;
    formGroups: FormGroup[];
    controlsData: any;
    hasRightPanel?: boolean;
    pageType?: GroupType;
    isReadOnlyMode?: boolean;
    additionalDocuments?: any[];
    organizerClients?: any[];
    rightPanelContent?: any;
    hasSignatureControls: boolean;
    tempFormGroupState?: FormGroup[];
    setTempFormDataState?: React.Dispatch<React.SetStateAction<FormGroup[]>>;
    onDocumentUpdate: (formGroups: any) => void;
    onControlUpdate: (oldControl: Control, newControl: Control, pageNo: number) => void;
    onSaveToolTip: (control: Control, pageNo: number) => void;
    handleAddDocumentClick?: () => void;
}

const AwesomePdfViewer: FC<AwesomePdfViewerProps> = ({
    leftPanelTabs,
    hasRightPanel,
    pageType,
    formGroups,
    isReadOnlyMode,
    additionalDocuments,
    organizerClients,
    rightPanelContent,
    documentUrl,
    hasSignatureControls,
    controlsData,
    tempFormGroupState,
    isSourceDocumentEnabled,
    setTempFormDataState,
    onControlUpdate,
    setCurrentForm,
    onSaveToolTip,
    handleAddDocumentClick
}) => {
    const [organizerBookmarks, setOrganizerBookmarks] = useState<any>(null);
    const [pages, setPages] = useState<any[]>(organizerBookmarks ? [...organizerBookmarks.pages] : []);
    const [totalPages, setTotalPages] = useState<number>(pages.length ?? 1); // TODO: Get total pages from the document
    const [currentPage, setCurrentPage] = useState<any>(pages[0]);
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    const [scale, setScale] = useState<number>(1);
    const [selectedSigner, setSelectedSigner] = useState<any>(organizerClients ? organizerClients[0] : {});
    const [showDefaultNavigationStartControl, setShowDefaultNavigationStartControl] = useState<boolean>(true);
    const [signatureData, setSignatureData] = useState<SignatureData>({ name: "", signature: "", signatureMode: 0 });

    let _toolbar: any = useRef<any>(null);
    let _viewPanel: any = useRef<any>(null);
    let _controlLayer: any = useRef<any>(null);
    let _controlList: any = useRef<any[]>([]);
    let _controlDisplayPanel: any = useRef<any>(null);
    let _bookmarkPanel: any = useRef<any>(null);

    const setReferences = () => {
        if (_toolbar && _toolbar.current) {
            _toolbar.current.setViewerReference(_viewPanel?.current);
        }
        if (_viewPanel && _viewPanel.current) {
            _viewPanel.current.setToolbarReference(_toolbar?.current);
            _viewPanel.current.setControlsReference(_controlList?.current);
            _viewPanel.current.setControlLayerReference(_controlLayer?.current);
            _viewPanel.current.setBookmarkPanelReference(_bookmarkPanel?.current);
        }
        if (_controlLayer && _controlLayer.current) {
            _controlLayer.current.setControlsReference(_controlList?.current);
            _controlLayer.current.setcontrolDisplayPanelReference(_controlDisplayPanel?.current);
        }
        if (_controlDisplayPanel && _controlDisplayPanel.current) {
            _controlDisplayPanel.current.setControlsReference(_controlList?.current);
            _controlDisplayPanel.current.setViewerReference(_viewPanel?.current);
        }
    }
    useEffect(() => {
        setReferences();
    }, []);

    useEffect(() => {
        if (formGroups && formGroups.length > 0) {
            const organizerBookmarks = GroupType.Engagement === pageType ? getEsignBookmarks(formGroups, "Bookmarks") : getOrganizerBookmarks(formGroups, "Bookmarks");
            setOrganizerBookmarks(organizerBookmarks);
            let tmpForm: Form | undefined = (formGroups as any)[0].forms.find((x: any) => x.individualFilePageNo === currentPageNumber);
            setCurrentForm && setCurrentForm(tmpForm);
            setTempFormDataState && setTempFormDataState([...formGroups]);
            setPages(organizerBookmarks ? [...organizerBookmarks.pages] : []);
            setTotalPages(organizerBookmarks ? organizerBookmarks.pages.length : 1);
        }
    }, [formGroups, pageType, currentPageNumber, setCurrentForm])

    useEffect(() => {
        setShowDefaultNavigationStartControl(hasSignatureControls);
    }, [hasSignatureControls]);

    useEffect(() => {
        const initialZoom = _viewPanel && _viewPanel.current && _viewPanel.current.getInitialScaleBaseOnScreenWidth() || 1;
        setScale(initialZoom)
    }, [_viewPanel]);

    const getSignatureControls = (formGroups: FormGroup[], type: GroupType): SignatureControlsDictionary => {
        let controls: SignatureControlsDictionary = {};
        if (formGroups && formGroups.length) {
            const index = formGroups.findIndex(formGroup => formGroup.type === type);
            if (formGroups[index] && formGroups[index].forms) {
                formGroups[index].forms.map((f: Form, index: number) => {
                    const form: EngagementLetterForm | OrganizerForm = type === GroupType.Engagement ? f as EngagementLetterForm : f as OrganizerForm;
                    if (!form.formData) {
                        form.formData = { controlValues: [] };
                    }
                    // TODO: Replace index with form.pageNo
                    controls[form.pageNo] = form.formData.controlValues;
                });
            }
        }
        return controls;
    }

    const getCurrentPageSize = (): PageSize => {
        if (_viewPanel && _viewPanel.current) {
            return _viewPanel.current.getPageSize(currentPageNumber, scale);
        }
        else {
            return PageSize.create(PDF_VIEWER_BASE_HEIGHT, PDF_VIEWER_BASE_WIDTH);
        }
    }

    const handleControlUpdate = (oldControl: Control, newControl: Control) => {
        onControlUpdate(oldControl, newControl, currentPageNumber);
    }


    const adjustSamePositionControl = (control: Control): Control => {
        const signatureControls: any = getSignatureControls(formGroups, GroupType.Organizer);
        let controls: any = signatureControls ? signatureControls[currentPage] : [];
        if (!signatureControls) {
            controls = [];
        }
        const index = controls.findIndex((x: any) => x.id === control.id);
        if (index !== -1) {
            control.boundingRectangle.left += 5;
            control.boundingRectangle.top += 5;
            return adjustSamePositionControl(control);
        }
        else {
            return control;
        }
    }

    // TODO: To be moved to helper if required
    const getCurrentForm = (): OrganizerForm | undefined => {
        const currentForm: OrganizerForm | undefined = (formGroups as any)[0].forms.find((x: any) => x.individualFilePageNo === currentPageNumber);
        return currentForm;
    }

    const getCurrentBlobForm = (formGuid: string): BlobForm | undefined => {
        const currentForm: BlobForm | undefined = controlsData.find((x: any) => x.formGuid === formGuid);
        return currentForm;
    }
    const setSignature = (controlProps: SignatureControlProps) => {
        // TODO: Set signature function call
        const control = _controlList.current.find((x: any) => x?.props?.id === controlProps.id);
        control && control.setControlData({
            name: signatureData?.name,
            signature: signatureData?.signature,
            signatureMode: signatureData?.signatureMode
        });

    }

    const setAllSignatureControlValueToSame = (signatureData: SignatureData, controlProps: SignatureControlProps) => {
        _controlList && _controlList.current.length > 0 && _controlList.current.filter((x: any) => x != null).forEach((item: any) => {
            item.setControlData({
                name: signatureData.name,
                signature: signatureData.signature,
                signatureMode: signatureData.signatureMode
            });

            setSignatureData({
                ...signatureData,
                signatureMode: signatureData.signatureMode,
                name: signatureData.name,
                signature: signatureData.signature
            })
            // TODO: Set signature function call
        });
    }

    const updateOrganizerFormControl = (blobForm: BlobForm, currentForm: OrganizerForm, controlData: any, controlPops: any, clientType: number, callback?: (control: any) => void): void => {

        const controlId: string = controlPops.id.replace(SignatureControlConstants.ControlIdPrefix, "");

        if (currentForm.formType == FormType.Organizer) {

            let tmpControlValue: ControlValue = {
                formGuid: currentForm.formGuid,
                controlGuid: controlId,
                value: "",
                filledBy: 0
            };

            let tmpFormControl: Control | undefined = blobForm.controlList.find(x => x.id == controlId);
            if (tmpFormControl) {

                if (tmpFormControl.controlType == ControlType.Textbox) {
                    tmpControlValue.value = controlData.text
                    tmpControlValue.filledBy = clientType
                }

                if (tmpFormControl.controlType == ControlType.Date) {
                    //format date as YYYY-MM-DD
                    tmpControlValue.value = moment(controlData.value).format("YYYY-MM-DD");
                    tmpControlValue.filledBy = clientType

                }

                else if (tmpFormControl.controlType == ControlType.Checkbox) { // TODO: Need to check
                    tmpControlValue.value = controlData.checked
                    tmpControlValue.filledBy = clientType
                }

                else if (tmpFormControl.controlType == ControlType.Dropdown) {
                    tmpControlValue.value = controlData.selectedItem
                    tmpControlValue.filledBy = clientType
                }
                else if (tmpFormControl.controlType == ControlType.RadioButtonGroup) {

                    controlData.forEach(function (radio: any) {

                        //const tmpRadio = formControl.controlData.items.find((x: any) => x.id == radio.id);

                        //if (tmpRadio) {
                        //    tmpRadio.controlData.selected = radio.value.checked;
                        //}

                        if (radio.value.checked) {
                            tmpControlValue.value = radio.id;
                            tmpControlValue.filledBy = clientType;
                        }
                    });
                }
                else if (tmpFormControl.controlType == ControlType.Signature) {
                    //formControl.controlData = SignatureData.create(controlData.name,
                    //    controlData.signature,
                    //    controlData.signatureMode);
                }
                else {
                    console.error("Control not implemented");
                }
            }

            let formControl: ControlValue | undefined = (currentForm.formData as OrganizerFormData).controlValues.find(x => x.controlGuid == controlId);

            if (formControl) {
                formControl.value = tmpControlValue.value;
                formControl.filledBy = tmpControlValue.filledBy;
            } else {
                (currentForm.formData as OrganizerFormData).controlValues = (currentForm.formData as OrganizerFormData).controlValues.concat(tmpControlValue);
            }
            let controlValueIndex: number = (currentForm as OrganizerForm).formData.controlValues.findIndex(x => x.controlGuid === controlId);

            if (controlValueIndex >= 0) {
                currentForm.formData.controlValues[controlValueIndex] = {
                    value: tmpControlValue.value,
                    filledBy: tmpControlValue.filledBy,
                    controlGuid: controlId,
                    formGuid: currentForm.formGuid
                }
            } else {
                (currentForm.formData as OrganizerFormData).controlValues = (currentForm.formData as OrganizerFormData).controlValues.concat([{
                    value: tmpControlValue.value,
                    filledBy: tmpControlValue.filledBy,
                    controlGuid: controlId,
                    formGuid: currentForm.formGuid
                }]);
            }
            const updatedFormGroups = tempFormGroupState && tempFormGroupState.map((formGroup: any) => {
                if (formGroup.type === currentForm.formType) {
                    return {
                        ...formGroup,
                        forms: formGroup.forms.map((form: any) => {
                            if (form.formGuid === currentForm.formGuid) {
                                return currentForm;
                            }
                            return form;
                        })
                    }
                }
                return formGroup;
            });

            setTempFormDataState && setTempFormDataState(updatedFormGroups ?? []);
        }
    }

    const updateOrganizerControl = (controlData: any, controlPops: any) => {
        if (controlPops?.name === "Date Picker" && moment(controlData.value).year() > 9999) {
            return;
        }
        const clientType = ClientType.Taxpayer;
        const currentForm: OrganizerForm | undefined = getCurrentForm();
        if (currentForm) {
            if (currentForm.formType == FormType.Organizer) {
                const currentBlobForm: BlobForm | undefined = getCurrentBlobForm(currentForm.formGuid);
                currentBlobForm && updateOrganizerFormControl(currentBlobForm, currentForm, controlData, controlPops, clientType);
            }
        }
    }

    const createControls = () => {
        const controlCollection: any[] = [];
        _controlList.current = [];
        const signatureControls: any = getSignatureControls(formGroups, GroupType.Organizer);
        const controls: any = signatureControls ?? [];
        // if (controls && Object.keys(controls).length > 0) {
        //     Object.entries(controls).map(([pageNo, value]) => {
        //         value && Array.isArray(value) && value.forEach((control: Control) => {
        //             const pageSize: PageSize = getCurrentPageSize();
        //             const pdfViewerTop = ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.boundingRectangle?.top) - getTopAdjustmentSignatureControlValue(control.controlType);
        //             const pdfViewerLeft = ControlBase.getPdfViewerControlLeftPosition(control.boundingRectangle?.left);
        //             const height = control.boundingRectangle?.height == 0 ? 30 : control.boundingRectangle?.height;
        //             const width = control.boundingRectangle?.width == 0 ? 150 : control.boundingRectangle?.width;
        //             const top = pdfViewerTop;
        //             const left = pdfViewerLeft;
        //             const controlText = getControlText(control.controlType, control.dataType);
        //             controlCollection.push(
        //                 /*@ts-ignore */
        //                 <SignatureControl
        //                     height={(height * scale)}
        //                     id={"control_" + control.id}
        //                     key={"control_key_" + control.id}
        //                     ref={(ref: any) => {
        //                         _controlList.current.push(ref);
        //                     }
        //                     }
        //                     page={Number(pageNo) ?? 1}
        //                     width={(width * scale)}
        //                     top={top}
        //                     left={left}
        //                     name={controlText}
        //                     helptext={control.tooltip}
        //                     onAddSignature={setAllSignatureControlValueToSame}
        //                     disabled={false}
        //                     onClick={setSignature}
        //                     isRequired={control.required}
        //                     // signatureData={signatureData}
        //                     draggable={false}
        //                 >
        //                 </ SignatureControl>);
        //         });
        //     });

        // }

        let tmpForm: any = formGroups && (formGroups as any)[0].forms?.find((x: any) => x.individualFilePageNo == currentPageNumber);

        if (tmpForm) {
            let pageNo = tmpForm.individualFilePageNo ? tmpForm.individualFilePageNo : tmpForm.pageNo;

            if (tmpForm.formType === FormType.Organizer) {

                const formData: any = tmpForm.formData;
                if (formData) {
                    const blobForm: any = controlsData.length ? controlsData.find((x: any) => x.formGuid == (tmpForm ? tmpForm.formGuid : "")) : []; // fetch corresponding control list from blob form data
                    if (blobForm && blobForm.controlList) {

                        isReadOnlyMode &&
                            blobForm.controlList.sort(compareFormControl);

                        const viewport: PageSize = getCurrentPageSize();
                        blobForm.controlList.forEach(function (control: any) {
                            control.readonly = (isReadOnlyMode ? true : control.readonly);
                            if (viewport && !(viewport.width > viewport.height) && control.boundingrectangle) {
                                if (control.boundingrectangle.left > viewport.width || control.boundingrectangle.top > viewport.height) {
                                    return;
                                }
                            }
                            let tmpControl: any = formData.controlValues?.find((x: any) => x.controlGuid == control.id);
                            if (!tmpControl) {
                                tmpControl = control.controlData;
                            }


                            const rotationDegree = (tmpForm as any)?.rotationDegree;

                            let pdfViewerTop = (viewport.width > viewport.height || rotationDegree === 90) ? ControlBase.getPdfViewerControlTopPosition(viewport.width, control.boundingRectangle.top) :
                                ControlBase.getPdfViewerControlTopPosition(viewport.height, control.boundingRectangle.top);
                            let pdfViewerLeft = ControlBase.getPdfViewerControlLeftPosition(control.boundingRectangle?.left);
                            pdfViewerLeft = pdfViewerLeft * scale;
                            pdfViewerTop = pdfViewerTop * scale;

                            switch (control.controlType) {

                                case 1: // TextBox
                                case 3: // TextBox

                                    let type: TextBoxTypes = TextBoxTypes.Textbox;
                                    let textboxData: TextBoxData = TextBoxData.createNullObject();

                                    if (control.controlType == 3) { // Date
                                        controlCollection.push(
                                            <DatePickerControl
                                                id={SignatureControlConstants.ControlIdPrefix + control.id}
                                                key={SignatureControlConstants.ControlKeyPrefix + control.id}
                                                isRequired={false}
                                                ref={(ref) => _controlList.current.push(ref)}
                                                helptext={control.helptext}
                                                page={pageNo}
                                                name={"Date Picker"}
                                                width={control.boundingRectangle.width * scale}
                                                height={control.boundingRectangle.height * scale}
                                                top={pdfViewerTop}
                                                left={pdfViewerLeft}
                                                placement={control.textPlacement}
                                                dateData={tmpControl.value === null || tmpControl.value === "Invalid date" ? DateData.createNullObject() : DateData.create(new Date(moment(tmpControl.value).add(0, 'd').format('MM/DD/YYYY')))}
                                                dateFormat="MM/dd/yyyy"
                                                onChange={updateOrganizerControl}
                                                cssClassName={getDatePickerClassName(tmpControl.filledBy)}
                                                disabled={isReadOnlyMode}
                                                highlightIfChanged={true}
                                            />);
                                        // }
                                    }
                                    else {

                                        if (control.dataType == 0 || control.dataType == 1) { // textbox & SSN
                                            type = TextBoxTypes.Textbox;
                                            textboxData = TextBoxData.create(tmpControl.value);
                                        }
                                        else if (control.dataType == 4) { // textarea
                                            type = TextBoxTypes.TextArea;
                                            textboxData = TextBoxData.create(tmpControl.value);
                                        }
                                        else if (control.dataType == 2 || control.dataType == 6) { // Number & Amount

                                            type = TextBoxTypes.Number;

                                            if (Number(tmpControl.value) > 0) {
                                                textboxData = TextBoxData.create(tmpControl.value);
                                            }

                                            controlCollection.push(<NumericTextboxControl
                                                id={SignatureControlConstants.ControlIdPrefix + control.id}
                                                key={SignatureControlConstants.ControlKeyPrefix + control.id}
                                                ref={(ref: any) => {
                                                    _controlList.current.push(ref)
                                                }}
                                                page={pageNo}
                                                textboxData={textboxData}

                                                width={control.boundingRectangle.width * scale}
                                                height={control.boundingRectangle.height * scale}
                                                placement={control.textPlacement}
                                                maxLength={control.maxLength}
                                                top={pdfViewerTop}
                                                left={pdfViewerLeft}
                                                name={"Textbox"}
                                                placeholder={control.placeholder}
                                                helptext={control.helptext}
                                                isRequired={false}
                                                disabled={control.readonly} type={type}
                                                data={control.customData}
                                                onChange={updateOrganizerControl}
                                                highlightIfChanged={true}
                                                color={
                                                    "#f0f"
                                                    //getControlColor(tmpControl.filledBy)
                                                } />);

                                            break;
                                        }

                                        else if (control.dataType == 5) { // Label

                                            controlCollection.push(<LabelControl
                                                id={SignatureControlConstants.ControlIdPrefix + control.id}
                                                key={SignatureControlConstants.ControlKeyPrefix + control.id}
                                                ref={(ref: any) => {
                                                    _controlList.current.push(ref)
                                                }}
                                                page={pageNo}
                                                value={LabelData.create(control.controlData.value)}
                                                width={control.boundingRectangle.width * scale}
                                                height={control.boundingRectangle.height * scale}
                                                placement={control.textPlacement}
                                                top={pdfViewerTop}
                                                left={pdfViewerLeft}
                                                name={"Label"}
                                                data={control.customData} />);

                                            break;
                                        }
                                    }

                                    controlCollection.push(<TextBoxControl
                                        id={SignatureControlConstants.ControlIdPrefix + control.id}
                                        key={SignatureControlConstants.ControlKeyPrefix + control.id}
                                        ref={(ref: any) => {
                                            _controlList.current.push(ref)
                                        }}
                                        page={pageNo}
                                        textboxData={textboxData}

                                        width={control.boundingRectangle.width * scale}
                                        height={control.boundingRectangle.height * scale}
                                        placement={control.textPlacement}
                                        maxLength={control.maxLength}
                                        top={pdfViewerTop}
                                        left={pdfViewerLeft}
                                        name={"Textbox"}
                                        placeholder={control.placeholder}
                                        helptext={control.helptext}
                                        isRequired={false}
                                        disabled={control.readonly} type={type}
                                        data={control.customData}
                                        onChange={updateOrganizerControl}
                                        highlightIfChanged={true}
                                        color={
                                            "#f0f"
                                            //getControlColor(tmpControl.filledBy)
                                        } />);

                                    break;

                                case 2:// checkbox

                                    controlCollection.push(<CheckBoxControl
                                        id={SignatureControlConstants.ControlIdPrefix + control.id}
                                        key={SignatureControlConstants.ControlKeyPrefix + control.id}
                                        ref={(ref: any) => {
                                            _controlList.current.push(ref)
                                        }}
                                        page={pageNo}
                                        checkBoxData={CheckBoxData.create((tmpControl.value === true || tmpControl.value === "True") ? true : false)}
                                        width={15}
                                        height={15}
                                        top={pdfViewerTop}
                                        left={pdfViewerLeft}
                                        name={"Checkbox"}
                                        helptext={control.helptext}
                                        isRequired={control.required}
                                        disabled={control.readonly} data={control.customData}
                                        onChange={updateOrganizerControl}
                                        cssClassName={getCheckBoxClassName(tmpControl.filledBy)} />);

                                    break;

                                case 4: // Dropdown

                                    controlCollection.push(<DropdownControl
                                        id={SignatureControlConstants.ControlIdPrefix + control.id}
                                        key={SignatureControlConstants.ControlKeyPrefix + control.id}
                                        ref={(ref: any) => {
                                            _controlList.current.push(ref)
                                        }}
                                        page={pageNo}
                                        value={DropdownData.create(tmpControl.value || control.defaultItem)}
                                        items={control.options}
                                        width={control.boundingRectangle.width * scale}
                                        height={control.boundingRectangle.height * scale}
                                        placement={control.textPlacement}
                                        top={pdfViewerTop}
                                        left={pdfViewerLeft}
                                        name={"List"}
                                        placeholder={control.placeholder}
                                        helptext={control.helptext}
                                        isRequired={control.required}
                                        disabled={control.readonly}
                                        data={control.customData}
                                        onChange={updateOrganizerControl}
                                        color={
                                            "#f0f"
                                            //ViewerHelper.getControlColor(tmpControl.filledBy)
                                        } />);

                                    break;

                                case 6: // Signature
                                    //Signature controls are not supported for organizer
                                    break;


                                case 8: // RadioButtonGroup


                                    let radioButtons: RadioButton[] = [];

                                    control.controlData.items.forEach(function (radio: any) {

                                        let selected: boolean = false;

                                        if (radio.id == tmpControl.value) {
                                            selected = true;
                                        }

                                        const tmpRadioButtonData = RadioButtonData.create(selected, "");
                                        const tmpRadioButton: RadioButton = RadioButton.create(radio.name,
                                            radio.boundingRectangle.top * scale,
                                            radio.boundingRectangle.left * scale,   
                                            radio.boundingRectangle.width * scale,
                                            radio.boundingRectangle.height * scale,
                                            tmpRadioButtonData);

                                        tmpRadioButton.id = radio.id;

                                        radioButtons.push(tmpRadioButton);

                                    });

                                    controlCollection.push(<RadioButtonControl
                                        id={SignatureControlConstants.ControlIdPrefix + control.id}
                                        key={SignatureControlConstants.ControlKeyPrefix + control.id}
                                        ref={(ref) => { _controlList.current.push(ref) }}
                                        page={pageNo}
                                        isRequired={control.required}
                                        disabled={control.readonly}
                                        width={control.boundingRectangle.width * scale}
                                        height={control.boundingRectangle.height * scale}
                                        top={pdfViewerTop}
                                        left={pdfViewerLeft}
                                        helptext={control.helptext}
                                        name={"Options"}
                                        items={radioButtons}
                                        onChange={updateOrganizerControl}
                                        color={
                                            "#f0f"
                                            // ViewerHelper.getControlColor(tmpControl.filledBy)
                                        } />);

                                    break;

                                default:
                                    break;
                            }
                        });
                    }
                }
            }
        }
        return controlCollection;
    }

    const onUpdateControlData = (control: Control) => {
        const signatureControls: any = getSignatureControls(formGroups, GroupType.OrganizerWithSignature);
        const draggedControl: any = signatureControls[currentPageNumber].filter((c: any) => c.id == control.id)[0];
        draggedControl.boundingRectangle.left = control.boundingRectangle.left;
        draggedControl.boundingRectangle.top = control.boundingRectangle.top;
        handleControlUpdate(draggedControl, control)
    }

    const onPageChanged = (pageProperties: PageProperties) => {
        setCurrentPageNumber(pageProperties.page);
        setCurrentPage(pages[pageProperties.page - 1]);
    }
    const goToFirstPage = () => {
        _viewPanel.current.gotoPage(1);
        setCurrentPage(pages[0]);
        setCurrentPageNumber(1);
    }

    const goToLastPage = () => {
        _viewPanel.current.gotoPage(pages.length);
        setCurrentPage(pages[pages.length - 1]);
        setCurrentPageNumber(pages.length);
    }

    const goToNextPage = () => {
        _viewPanel.current.gotoPage(currentPageNumber + 1);
        setCurrentPage(pages[currentPageNumber + 1]);
        setCurrentPageNumber(currentPageNumber + 1);
    }

    const goToPreviousPage = () => {
        _viewPanel.current.gotoPage(currentPageNumber - 1);
        setCurrentPage(pages[currentPageNumber - 2]);
        setCurrentPageNumber(currentPageNumber - 1);
    }

    const goToSelectivePage = (pageNumber: number) => {
        _viewPanel.current.gotoPage(pageNumber);
        setCurrentPage(pages[pageNumber - 1]);
        setCurrentPageNumber(pageNumber);
    }

    const handlePdfDocumentLoad = () => {
        if (formGroups && formGroups.length > 0 && formGroups[0].forms.length > 0 && _viewPanel.current) {

            // TODO: Refactor the below code as per the requirement 

            // const page: any = formGroups[0].forms.find((x: any) => x.formData.controlList.length > 0);
            // if (page && _viewPanel && _viewPanel.current) {
            //     _viewPanel.current.gotoPage(page.pageNo);

            // }
            // //some delay to load the pdf pages with controls

            // setTimeout(
            //     () => {
            //         _controlLayer.current.startNavigation();
            //     },
            //     2000);
        }
    }

    const onNavigationBegin = () => {
        setShowDefaultNavigationStartControl(false);
    }

    return (
        <>
            {/* @ts-ignore */}
            <PdfViewer>
                <Header>
                    {/* @ts-ignore */}
                    <Toolbar ref={_toolbar}
                        customToolbarPosition={'right'}
                        hideReadOnly={true}
                        hideRightPanel={!hasRightPanel}
                        hideLeftPanel={false}>
                        <Pagination
                            currentPage={currentPageNumber}
                            onFirstPage={goToFirstPage}
                            onNextPage={goToNextPage}
                            onPreviousPage={goToPreviousPage}
                            onLastPage={goToLastPage}
                            onGotoPage={goToSelectivePage}
                            totalPages={totalPages || 0} />
                        <Zoom />
                        <p><i className="arrow-down"></i></p>
                        <CustomOptions position="right" />
                    </Toolbar>
                </Header>
                <div className='main'>
                    <LeftPanel>
                        <BookmarkPanelConent
                            bookmarks={organizerBookmarks}
                            additionalDocuments={additionalDocuments}
                            formType={pageType ?? GroupType.None}
                            isBatchEsign
                            isSourceDocumentSettingEnabled={isSourceDocumentEnabled}
                            handleAddDocumentClick={handleAddDocumentClick}
                            accordianItems={leftPanelTabs} onPageSelect={goToSelectivePage} />
                    </LeftPanel>
                    {
                        /* @ts-ignore */
                        <ControlLayer
                            ref={_controlLayer}
                            useDefaultNavigationStartControl={showDefaultNavigationStartControl}
                            onNavigationComplete={() => { }}
                            onNavigationBegin={onNavigationBegin}

                        >
                            {createControls()}
                        </ControlLayer>
                    }
                    {/* @ts-ignore */}
                    <ViewPanel
                        ref={_viewPanel}
                        onScaleChanged={(scale: number) => {
                            setScale(scale)
                        }}
                        onPageChanged={onPageChanged}
                        onDocumentLoad={handlePdfDocumentLoad}
                        pageMode={PageMode.SinglePage}
                        pdfSource={PdfSource.createFromUrl(documentUrl)}
                        disableTextLayer={true}
                    >
                    </ViewPanel>
                    {hasRightPanel && <RightPanel>
                        <RightPanelContent
                            isTogglable={pageType === GroupType.Organizer}
                            isReadOnlyMode={isReadOnlyMode}
                            rightPanelContent={rightPanelContent}
                        />
                    </RightPanel>
                    }
                </div>
            </PdfViewer >

        </>
    );
}
export default AwesomePdfViewer;