import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonComponent } from 'cp-common-ui-components';
import { FileViewer } from "./FileViewer";
import { GatherUploadedDocuments } from '../../../helper/Constants';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { getCategoryList, getDocumentRequestList, updateDocumentType } from '../../../store/services/documentRequestList';
import { useClientId } from '../../hooks/client-hook';
import DRLDropdown from 'pages/drl-page/DRLDropdown';

interface PreviewUplodedDRLFileModalProps {
    url: string,
    showPDFPreview: boolean;
    customClass: string | '';
    onHidePDFPreview: () => void;
    fileType: string | undefined;
    documentType: string;
    fileName: string;
    organizerStep?: boolean;
    documentId: number | undefined;
    isStepCompleted: boolean;
    fileId: number;
}

const PreviewUplodedDRLFileModal: React.FC<PreviewUplodedDRLFileModalProps> = ({
    url,
    showPDFPreview,
    customClass,
    documentType,
    fileType,
    fileName,
    onHidePDFPreview,
    organizerStep,
    documentId,
    isStepCompleted,
    fileId
}) => {

    const [docUrl, setDocUrl] = useState("");

    const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
    const [selectedRadio, setSelectedRadio] = useState<string>('');
    const dispatch = useAppDispatch();
    const [clientId] = useClientId();

    useEffect(() => {
        setDocUrl(url);
    }, [url]);

    const handleClose = () => {
        onHidePDFPreview();
        setDocUrl("")
        setSelectedRadio('');
        setCheckedItems({});
    };
    const handleUpdate = () => {
        const payload = {
            documentId: documentId,
            name: "",
            gatherDocumentRequestIds: selectedRadio ? [selectedRadio] : Object.keys(checkedItems).filter(item => checkedItems[item]),
            isOtherOptionEnabled: false,
            categoryId: !!selectedRadio ? 0 : 1,
        }
        dispatch(updateDocumentType(clientId, payload, () => {
            dispatch(getCategoryList(clientId));
            dispatch(getDocumentRequestList(clientId));
            handleClose();
        }, () => {
            handleClose();
        }))
    };
    return <div>
        <Modal className={`file-preview-modal white-modal ${customClass}`}
            show={showPDFPreview} onHide={() => {
                onHidePDFPreview();
                setDocUrl("")
                setSelectedRadio('');
                setCheckedItems({});
            }}>
            <div>
                <Modal.Header closeButton data-test-auto="">
                    <Modal.Title className='file-preview-modal-title'>
                        {GatherUploadedDocuments.PreviewDocument}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='file-preview-modal-body'>
                    {!organizerStep && <div className='file-name'>{fileName}</div>}
                    {!organizerStep &&
                        <>
                            <div className='preview-label'>Document Type</div>
                            {showPDFPreview && <DRLDropdown fileId={fileId} checkedItems={checkedItems} selectedRadio={selectedRadio} setCheckedItems={setCheckedItems} setSelectedRadio={setSelectedRadio} />}
                        </>
                    }
                    <div className='preview-label'>Preview</div>
                    {docUrl && <FileViewer url={docUrl} fileType={fileType} />}
                </Modal.Body>
                {!organizerStep ? <Modal.Footer className='file-preview-modal-footer'>
                    <ButtonComponent disabled={isStepCompleted} onClick={handleUpdate}>Update</ButtonComponent>
                </Modal.Footer> : <></>}
            </div>
        </Modal>
    </div >
}
export default PreviewUplodedDRLFileModal;

