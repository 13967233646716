// Utility functions for managing localStorage with expiration
export const StorageWithExpiry = {
    /**
     * Set an item in localStorage with an expiration time
     * @param {string} key - The key to store the item under
     * @param {*} value - The value to store
     * @param {number} ttl - Time to live in milliseconds
     */
    set(key: string, value: string, ttl: number) {
      const now = new Date();
      
      // Create an object with the original value and expiration time
      const item = {
        value: value,
        expiry: now.getTime() + ttl
      };
      
      // Store the item in localStorage as a JSON string
      localStorage.setItem(key, JSON.stringify(item));
    },
    
    /**
     * Get an item from localStorage, checking for expiration
     * @param {string} key - The key of the item to retrieve
     * @returns {*|null} The stored value or null if expired or not found
     */
    get(key: string) {
      // Retrieve the item from localStorage
      const itemStr = localStorage.getItem(key);
      
      // If no item exists, return null
      if (!itemStr) {
        return null;
      }
      
      // Parse the stored JSON
      const item = JSON.parse(itemStr);
      
      // Check if the item has expired
      const now = new Date();
      if (now.getTime() > item.expiry) {
        // If expired, remove the item and return null
        localStorage.removeItem(key);
        return null;
      }
      
      // Return the original value
      return item.value;
    },
    
    /**
     * Remove an item from localStorage
     * @param {string} key - The key of the item to remove
     */
    remove(key: string) {
      localStorage.removeItem(key);
    },
    
    /**
     * Clear all items from localStorage
     */
    clear() {
      localStorage.clear();
    }
  };