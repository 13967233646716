import { TAXPAYER } from "../route/paths";

export const ButtonConstant = {
    Submit: "Submit",
    Cancel: "Cancel",
    Next: "Next",
    Back: "Back",
    Skip: "Skip",
    Finish: "Finish",
    Ok: "Ok",
    Confirm: "Confirm",
    Save: "Save",
    Delete: "Delete",
    Process: "Process",
    Preview: "Preview",
    Close: "Close",
    DownloadCSV: "Download CSV Template",
    IgnoreErrors: "Ignore Errors",
    SaveForLater: "Save for later",
    ClickToUpload: "Click to upload",
    ChooseFile: " Choose File",
    SaveAndContinue: "Save & Continue",
    Refresh: "Refresh",
    Remap: "Remap",
    LeaveUncategorized: "Leave Uncategorized",
    SendCode: "Send Code",
    GoHome: "Go Home"
};

export const OrganizerConstant = {
    ImportantNote: "Important :",
    DoNotUpload: "Encrypted files, password-protected, or locked documents are not supported. Documents uploaded can be viewed/downloaded from Summary -› Source document page",
    TaxSoftware: "Tax Software",
    BatchName: "Batch Name",
    ERO: "ERO",
    EngagementType: "Engagement Type",
    TaxYear: "Tax Year",
    OfficeLocation: "Office Location",
    SelectZip: "Select Zip File Name",
    SelectCSV: "Select CSV File",
    FileSizeLimit: "File size limit is 1GB.",
    IncludeEL: "Include Engagement Letter",
    IncludeOrganizers: "Includes Fillable Organizers",
    ProvideSourceDocuments: "Provide Source Documents",
    SourceDocumentsTypeText: "Please upload all wages, pensions, gambling winnings forms.",
    DocumentsUploaded: "Document(s) uploaded",
    NotesToTaxPreparer: "Notes to Tax Preparer",
    NotesForTaxPreparer: "Add Notes for tax preparer",
    Notes: "Notes",
    CompleteOrganizerConfirmation1: "Are you sure you've answered all questions and completed all necessary information for you and this tax year?",
    CompleteOrganizerConfirmation2: "Would you like to send a notification to your spouse requesting their review before finalizing the Organizer?",
    AttentionMessage: "Please ensure that your spouse is not currently working on this Organizer",
    SpouseRequiredModalTitle: "Spouse’s Email Confirmation",
    SpouseRequiredModalText: "Please provide an email address for your spouse. it may be the same as your own if you share an email account. The recipient will receive a request asking them to review the organizer.",
};

export const AuthenticationLayoutConstants = {
    helloText: "Hello,",
    preparedByText: "Prepared by",
    termsOfService: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    needHelpText: "Need help?",
    contactUsText: "Contact Us",
    termsLink: "https://safesend.com/legal-policies/terms-of-use/",
    privacyLink: "https://safesend.com/legal-policies/privacy-statement/",
};

export const SSNAuthPageConstants = {
    title: "Verify your identity",
    helperText: "Enter the last four digits of your Social Security Number",
    ssnInputLabel: "Last 4 digit of SSN",
    ssInputPlaceholder: "Enter your SSN",
    confirmButton: "Confirm",
    ssnLength: 9
};

export const LinkLockPageConstants = {
    lockTextFirstLine: "The access link has  been locked .\n",
    lockTextSecondLine: "Please try again in ",
    minutesText: "five minutes ",
    lockTextAfterMinutes: "or contact your firm for assistance."
};

export const OtpPageConstants = {
    title: "Verify your identity",
    subtitle: "Request Access Code",
    helperText: "We will send you a One-Time Access Code \n to your email address.",
    helperTextMFA: "We will send you a One-Time Access Code \n to your mobile device number: ",
    otpHelperText: "A One-Time Access Code has been sent to \n your email address; it ",
    otpHelperTextMFA: "A One-Time Access Code has been sent to you\nvia a text message.",
    otpExpiresText: "expires in 20 minutes.",
    otpToasterText:"A One-Time Access Code was sent.",
    otpJunkText: "If you haven't received the code yet, please check your spam/junk folder.",
    resendCode: "Resend Code",
    otpIncorrectText: "Incorrect code! You have <n> more attempts left.",
    otpLength: 8,
    serverError: "Internal Server Error, Please try again",
    otpExpiredText: "Your one-time access code has expired.",
    otpLocked: "Your access has been locked due to too many failed attempts. Please try again in 5 minutes or contact your CPA.",
    sessionExpiry: "Your session will expire in ",
    time: " <n> seconds.",
    continueWorking: "Do you want to continue working?"
};

export const SignatureControlConstants = {
    ControlIdPrefix: "control_",
    ControlKeyPrefix: "control_key_"
};

export const AdditionalEsignAdjustmentsContants = {
    CheckBoxTopAdjustment: 13,
    ControlItemLeftAdjustment: 30,
    PdfViewerTopAdjustment: 10
};

export const HelpTextConstant = {
    ClientSignature: "Drag and drop to place client's signature",
    SignatureDate: "Drag and drop to place date of signature",
    Initial: "Drag and drop to place Initials control",
    Name: "Drag and drop to place Name control",
    Title: "Drag and drop to place Title control",
    Email: "Drag and drop to place Email Address control",
    CustomQuestion: "Drag and drop to place Custom Question control",
    Text: "Drag and drop to place Text control",
    Checkbox: "Drag and drop to place Checkbox control",
    RadioButton: "Drag and drop to place Radio Button control"
};

export const SignaturePanelConstants = {
    Signature: {
        header: "Signature Settings",
        title: "To make this signature mandatory, please check box below.",
        tooltipContent: ""
    },
    Default: {
        header: "Tool Tip",
        title: "Add Text",
        tooltipContent: "This text will help the signer to enter the necessary details"
    },
    CheckBoxControl: {
        header: "Checkbox properties",
        title: "How many checkbox(es) would you like to add?",
        tooltipContent: "Mention the count of checkbox(es) to be added. All checkboxes will be selectable by Taxpayer."
    },
    RadioButtonControl: {
        header: "Radio button properties",
        title: "How many radio button(s) would you like to add?",
        tooltipContent: "Mention the count of radio button(s) to be added. For one set Taxpayer can select only one option."
    }
}

export const FileUploadConstants = {
    ClickOrDrag: "Click or drag file",
    MaxFileName: "*Maximum file name length 200 characters",
    AdditionalDRL: "Additional DRL"
};

export const ValidationContants = {
    PhoneNumberWarning: 'Please enter the phone number.',
    PhoneNumberLengthWarning: 'Please enter a 10 digit phone number.',
    CountryCodeWarning: 'Please select the country code',
    EmailAddressWarning: "Please enter a valid email address.",
};

export const MyAccountConstants = {
    MobileNumber: 'Mobile Number',
    MobileNumberAccessCode: 'An access code will be sent every time you login.',
    MobileNumberLabel: "Enter Mobile Number",
    SpouseName: "Spouse's Name: ",
    TaxPayerName: "Tax Payer's Name: ",
    EmailAddressLabel: "Email Address",
    MobileLabel: "Mobile",
    Greeting: "Hi",
    Profile: "Profile",
    OneHub: "Access Client Portal",
    LogOut: "Log Out",
    NumberValidation: "Please Enter Valid Number",
    EmailValidation: "Please Enter Valid Email",
    ClientViewUpdateWarn : "Update is not allowed in Preview/Client View",
    OneHubWarning: "Client Portal is not available in Preview"
};

export const LandingPageConstants = {
    IntoText: "Let's start completing your tax return journey:",
    Completed: "Completed",
    EstimatedText: "This may take ",
    GetStarted: "Get Started",
    Continue: "Continue",
    NeedHelp: "Need Help?",
    Welcome: "Welcome",
    ToGetStarted: "To get started, we'll need some information from you.",
    MessageFromCPA: "A message from your firm:",
    Hi: "Hi",
    CompletedText: "All tasks have been successfully completed, and no further action is necessary. Thank you!",
    AwaitingText: "Awaiting for the first signer"
};

export const HeaderConstants = {
    ContactInformation: "Contact Information",
    Authorization: "Authorization",
    Bearer: "Bearer"
};

export const ESignConstants = {
    FinishConfirmationTitle: "Engagement Letter Complete",
    FinishConfirmationMessage: `Are you sure ?`,

    FinishConfirmationConfirmText: "Yes",
    FinishConfirmationCancellText: "cancel",

    SigningCompletedMessage: "Successfully signed Engagement letter, You will be redirected to Organizer page",

    UploadingSignatureMessage: "Uploading Signature ...",
    SubmitDocumentMessage: "Saving Sign info ...",

    SpouseInfoUpdateModalTitle: "Spouse's Signature Required",
    SpouseInfoUpdateModalBodyText: "Please provide an email address for your spouse. It may be the same as your own if you share an email account. The recipient will receive a similar request asking them to Sign their forms electronically.",

    SpouseNameTitle: "Spouse's Name : ",
    SpouseEmailTitle: "Email Address ",

    SpouseEmailValidationError: "Enter a valid email address.",

    DeclineModalBodyText: "If you select CONTINUE, this document will be voided and inaccessible to other signers.",

    DeclineRemarksTextboxLabel: "Please enter the reason for declining below:",
    DeclineRemarksModalTitle: "Decline Engagement Letter",
    DownloadEngagementLetterFileName: "EngagementLetter.pdf"
};

export const PreviewConstants = {
    GeneratingPreviewMessage: "Generating Document Preview. Please wait..",
    GeneratingPreviewTimeLimitExceededMsg: "Generating Document Preview is taking longer than expected. Please try again",
    GeneratingPreviewFailedMessage: "Generating Document Preview failed. Please try again",
    IsPreviewMode: "IsPreviewMode",
    IsClientView: "IsClientView",
    DeleteDocumentMessage: "Document deletion is not allowed in Client View",
    FinishMessage: "Finish is not allowed in Client View/Preview.",
    DownloadMessage: "Document is not available for download in ClientView/Preview",
    PreviewNotSupported: "Preview unavailable. Unsupported file format.",
    PreviewNotAllowed: "Preview is not allowed in Client View/Preview",
    EditNotAllowed: "Edit is not allowed in Client View/Preview",
    DeleteNotAllowed: "Delete is not allowed in Client View/Preview",
    PrintNotAllowed:"Print is not allowed in Client View/Preview"
};

export const FormPreviewConstants = {
    RequiredValidation: "This field is required",
    ParagraphTypeQuestion: {
        placeholder: "Answer goes here",
        maxLength: 3250
    },
};

export const ValidationAlertConstants = {
    ModalBodyText: "Please complete the questionnaire by filling in the mandatory fields:",
    FORM_VALIDATION_ERROR: "Please fill in the required fields",
};

export const EsignConstants = {
    SpouseRequiredModalText: "Please provide an email address for your spouse. It can be the same as yours if you share an email account. Your spouse will receive a similar request asking them to electronically sign the forms.",
    SpouseRequiredModalTitle: "Spouse’s Signature Required",
    DeclineTitle: 'Reviewed Document Confirmation',
    DownloadEngagementLetterFileName: "EngagementLetter.pdf",
};

export const UploadFileWarningMessage = (fileCount: number, totalFileCount: number, warningMessage: string) => `Unable to upload ${fileCount} out of ${totalFileCount} file(s), ${warningMessage}`;

export const sourceDocumentsUploadOrgWarningMessage = (fileCount: number, warningMessage: string) => `Unable to upload ${fileCount} file, ${warningMessage}`;

export const GatherUploadedDocuments = {
    SupportedUploadFileSizeWarningMessage: "Uploaded file(s) is too large. Please upload file(s) less than 500MB.",
    SupportedFilesMessage: "Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.",
    SupportedFilesSizeMessage: 'Please upload a file size greater than 0kb.',
    UploadInProgress: "Uploading In Progress...",
    ChooseFileOrDragDrop: "Choose a file or drag and drop here",
    FileTypes: "Please upload file size no more than 500 MB.",
    ChooseFile: "Choose File",
    UploadNotAllowedMessage: "Upload not allowed in preview mode",
    ClickToPreview: "Click to Preview",
    ClickToEdit: "Click to Edit",
    ClickToDelete: "Click to Delete",
    Hello: "Hello",
    Instruction: "Please provide the following documents required for your 2023 Tax Return process.",
    Uncategorized: "Uncategorized",
    Recognition: "Recognition is happening behind the scenes; you can leave the page and come back later",
    PreviewDocument: "Preview Document",
    NotAvailableFormType: "Not Available",
    OtherCategoryName: "Other",
    DocumentRequestTypeUpdated: "Document type updated successfully.",
    DocumentDeleted: "Document deleted successfully.",
    NotApplicable: "NA",
    UndoNotApplicable: "Undo NA",
    NAToolTip: "Mark as not applicable",
    UndoNAToolTip: "Request item marked as not applicable by taxpayer"
}

export const SourceDocumentConstants = {
    Added: "Added",
    ReMapTitle: "Attention!"
}

export const FinishDocumentUploadModalConstants = {
    Title: "Finish Document Upload",
    Content: "Have you finished uploading all necessary source documents? Once confirmed, you won't be able to upload any more documents."
};

export const ConfirmCompletionModalConstants = {
    Title: "Confirm Completion",
    Content1: "Please ensure you've entered all your information. After you click \"Finish,\" the Organizer will be marked as complete, preventing further changes.",
    Content2: "You can send the Organizer to your spouse for review using the \"Send to My Spouse\" option. However, the Organizer won't be marked as complete until the \"Finish\" button is clicked.",
    SendToMySpouse: "Send To My Spouse",
    Finish: "Finish",
}

export const AutoSave = {
    Success: "Autosave successful",
    Failure: "Autosave failed"
}

export const AutoSaveTime = 15 * 60 * 1000;

export const ErrorMessages = {
    UploadDocumentError: "Error occurred while processing uploaded document",
    RequestDocumentsError: "Error occured while fetching Requested Files",
}

export const DisplayError = [
    "ERROR_DELETED_RETURN",
    "ERROR_RECALLED_RETURN",
    "ERROR_INVALID_LINK",
    "OTP_LOCKED",
    "RETURN_DECLINED_FOR_SIGN",
    "OTP_EXPIRED",
    "ERROR_INVALID_OTP",
];

export const LockOutTime = 5 * 60 * 1000;

export const StepName = {
    Sign: "Sign",
    Complete: "Complete",
    Upload: "Upload",
    Answer: "Answer"
}

export const PageURL: { [key: string]: string } = {
    "Sign": TAXPAYER.E_SIGN,
    "Complete": TAXPAYER.ORGANIZER,
    "Upload": TAXPAYER.UPLOAD_DOCUMENTS,
    "Answer": TAXPAYER.CUSTOM_QUESTIONS
}

export const InvalidPageConstants = {
    InvalidLink: "Invalid link! Please contact your firm for further assistance.",
    SessionTimeOut: "Session timed out due to inactivity."
}

export const ClosedByFirmConstants = {
    ClosedByFirm: "Your Gather has been closed to prevent any further use. If you have any questions please contact your CPA."
}

export const StepFullName = {
    [StepName.Sign]: "Sign Documents",
    [StepName.Complete]: "Complete Organizer",
    [StepName.Upload]: "Upload Documents",
    [StepName.Answer]: "Answer Questionnaire"
};

export const SignalRConstants = {
    headers: {
        userId: 'x-ms-signalr-userid',
        clientType: 'ClientType'
    },
    TP: 'TP',
    GTR: 'GTR'
};

export const StatusUpdateConstants = {
    message: "Changes has been made to this document. Please refresh.",
    title: "Status Updated!"
};

export const PreviewPageConstant = {
    PreviewLoading: "Preview is loading please wait....",
    GeneratingPreviewTimeLimitExceededMsg: "Generating Document Preview is taking longer than expected. Please try again",
    GeneratingPreviewFailedMessage: "Generating Document Preview failed. Please try again",

    ClientViewLoading: "Client view is loading please wait....",
    GeneratingClientViewTimeLimitExceededMsg: "Generating client view is taking longer than expected. Please try again",
    GeneratingClientViewFailedMessage: "Generating client view failed. Please try again",

    UploadedRestrictedMessage: "Uploaded not allowed in preview or client view."
}

export const UserProfileConstant = {
    SaveSuccessMessage: "Account Details Updated Successfully"
}

export const OtherRecognizedFiles = "Other Recognized Files";

export const APIConstants = {
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE',
    POST: 'POST',
    include: 'include'
}

export const DrlDeleteConstants = {
    Title: "Delete File Confirmation",
    Body: "This file is associated with multiple request items. By deleting this file it will be removed from all other requested items as well.",
    NoKeep: "No, Keep",
    YesDelete: "Yes, Delete",
    Continue: "Do you want to continue?",
}

export const DRLPredictionConstants = {
    Success: "Successfully completed recognition for source document uploaded.",
    Failed: "Error in AI recognitions service. Please try again later.",
}

export const UserLoggedIn = "UserLoggedIn";
