import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DRLPredictionStatus, StatusType } from "common/enum";
import {
    DRLPredictionRequestStatus,
    IDocumentDRLPredictionStatus,
    IDRLPredictionRequest,
    IDRLPredictionRequestState
} from "common/model/document-drl-prediction";
import { DRLPredictionConstants } from "helper/Constants";
import { Toaster } from "common/components/Toasts";

const initialDRLPredictionRequestState: IDRLPredictionRequestState = {
    drlPredictionRequests: [],
}

const drlPredictionDetailSlice = createSlice({
    name: 'documentDRLPredictionDetail',
    initialState: initialDRLPredictionRequestState,
    reducers: {
        setDRLPredictionDetail(state, action: PayloadAction<IDRLPredictionRequest>) {
            state.drlPredictionRequests.push(action.payload);
        },
        updateDRLPredictionStatus(state, action: PayloadAction<IDocumentDRLPredictionStatus>) {
            const { documentGuid, status } = action.payload;
            const drlPredictionRequest = state.drlPredictionRequests.find(d => d.documentGuids.some(d => d.documentGuid === documentGuid));
            if (drlPredictionRequest) {
                drlPredictionRequest.updateDRLPredictionStatus(documentGuid, status);
                
                if (drlPredictionRequest.isAllDocumentsFailed()) {
                    drlPredictionRequest.updateRequestStatus(DRLPredictionRequestStatus.Failed);
                    Toaster.error(DRLPredictionConstants.Failed);
                    drlPredictionRequest.updateRequestStatus(DRLPredictionRequestStatus.Completed);
                }
                else if (drlPredictionRequest.isAllDocumentsProcessed()) {
                    drlPredictionRequest.updateRequestStatus(DRLPredictionRequestStatus.Success);
                    Toaster.success(DRLPredictionConstants.Success);
                    drlPredictionRequest.updateRequestStatus(DRLPredictionRequestStatus.Completed);
                }
            }            
        },
        addDocumentGuidToDRLPredictionRequest(state, action: PayloadAction<{ requestId: string, documentGuid: string, status: DRLPredictionStatus }>) {
            const { requestId, documentGuid, status } = action.payload;
            const drlPredictionRequest = state.drlPredictionRequests.find(d => d.requestId === requestId);
            if (drlPredictionRequest) {
                if (!drlPredictionRequest.isDocumentExists(documentGuid)) {
                    drlPredictionRequest.addDocumentGuid(documentGuid, status);
                    if(drlPredictionRequest.requestStatus === DRLPredictionRequestStatus.New){
                        drlPredictionRequest.updateRequestStatus(DRLPredictionRequestStatus.InProgress);
                    }
                }
            }
        },
        //update drl prediction request status as completed
        updateDRLPredictionRequestStatus(state, action: PayloadAction<{ requestId: string, drlPredictionRequestStatus: DRLPredictionRequestStatus }>) {
            const { requestId, drlPredictionRequestStatus } = action.payload;
            const drlPredictionRequest = state.drlPredictionRequests.find(d => d.requestId === requestId);
            if (drlPredictionRequest) {
                drlPredictionRequest.updateRequestStatus(drlPredictionRequestStatus);
            }
        }
    }
})

export const {
    setDRLPredictionDetail,
    updateDRLPredictionStatus,
    addDocumentGuidToDRLPredictionRequest,
    updateDRLPredictionRequestStatus} = drlPredictionDetailSlice.actions;

export default drlPredictionDetailSlice.reducer;