import AwesomePdfViewer from 'common/components/pdf-viewer/AwesomePdfViewer';
import React, { useEffect, useState } from 'react';
import { AccordianItemType, ClientType, FormGroup, GroupType, HeaderType } from 'common/enum';
import { CustomModal } from 'common/components/UIComponents/CustomModal';
import { ButtonComponent, FooterComponent } from 'cp-common-ui-components';
import FileUploadModal from './FileUploadModal';
import { DeleteIcon } from 'assets/icons/IconCollection';
import CheckBoxComponent from 'common/components/UIComponents/CheckBoxComponent';
import { ButtonConstant, OrganizerConstant, PreviewConstants, PreviewPageConstant, StepName } from 'helper/Constants';
import Header from 'common/components/layout/Header';
import { Footer } from 'pages/custom-questions/Footer';
import {
  autoSaveOrganizer,
  deleteTaxPayerNotes,
  finishOrganizer,
  getFormControlsJson,
  getOrganizerData,
  getUploadedSourceDocuments,
  saveOrganizer,
  saveTaxPayerNotes,
  sendOrganizerForReview,
  reviewOrganizer
} from 'store/services/organizer';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { ClientModel, initialClientModel } from 'common/model/clients';
import { getSpouseInfo, hideWarning, saveClientDetails } from 'store/services/clients';
import { IOrganizerSaveModel } from 'common/model/organizer';
import { useNavigate } from 'react-router-dom';
import { PreviewIcon } from 'assets/svg/SVGIconsCollection';
import { deleteUploadedDocument, getSourceDocumentPreview, requestUploadedDocumentStatus } from 'store/services/sourceDocumentUpload';
import { Toaster } from 'common/components/Toasts';
import PreviewUplodedDRLFileModal from 'common/components/file-upload/PreviewUploadedDRLFileModal';
import { useAutoSave } from 'common/hooks/auto-save';
import { useUpdateCurrentStep } from 'common/hooks/step-hook';
import { TAXPAYER } from '../../route/paths';
import useNextNavigation from '../../common/hooks/useNextNavigation';
import { useClientId } from 'common/hooks/client-hook';
import './index.scss';
import { getGatherMetaData } from 'store/services/esign';
import { isValidEmailAddress } from '../../helper/Validations';
import { AppState } from 'store';
import { getGatherCompanySettingsModel } from 'store/services/companySettings';
import { ReviewStatus } from 'common/model/organizer';
import { Button } from 'react-bootstrap';
import { useCompleted } from 'common/hooks/useCompletedHook';
import { MemoizedSignalRWebSocket } from 'common/components/signalR/SignalRWebSocket';

const OrganizerComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const [document, setDocument] = useState<any>({});
  const [showOrgCompleteModal, setShowOrgCompleteModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showAttentionModal, setShowAttentionModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [isAttentionModalChecked, setIsAttentionModalChecked] = useState(false);
  const [controlsData, setControlsData] = useState<any>({});
  const [currentForm, setCurrentForm] = useState<any>(null);
  const [showSpouseRequiredModal, setShowSpouseRequiredModal] = useState(false);
  const [spouseInfo, setSpouseInfo] = useState<ClientModel>(initialClientModel);
  const [spouseEmail, setSpouseEmail] = useState('');
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { previewDocumentData } = useAppSelector(state => state.sourceDocumentUploadReducer);
  const [tempFormGroupState, setTempFormDataState] = useState<FormGroup[]>([]);
  const [disableNoteSave, setDisableNoteSave] = useState(true)


  const isSourceDocumentSettingEnabled: boolean = useAppSelector((state: AppState) => state.companySettings?.sourceDocumentSettings?.enabledSourceDocuments);
  const isSourceDocumentCompeleted: boolean = useAppSelector((state: AppState) => state.sourceDocumentUploadReducer?.uploadedDocumentStatus?.isSourceDocumentCompleted);
  const isClientView = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isClientView);
  const isPreview = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isPreview);
  const clientState = useAppSelector((state: AppState) => state.clientReducer);
  const [clientId] = useClientId();
  const navigate = useNavigate();
  const nextNavigation = useNextNavigation();
  useAutoSave(tempFormGroupState, document?.formGroups, () => {
    const saveOrganizerPayload: IOrganizerSaveModel = {
      formGroup: document?.formGroups[0],
      isReviewed: false,
      signature: ""
    }
    dispatch(autoSaveOrganizer(clientId, saveOrganizerPayload, (data: any) => {
      Toaster.success("Auto saved successfully.");
    }));
  })

  useUpdateCurrentStep(StepName.Complete)
  useEffect(() => {
    setDisableNoteSave(true)
  }, [currentForm?.pageNo])


  useCompleted(StepName.Complete)


  useEffect(() => {
    dispatch(getGatherMetaData(clientId));
    dispatch(getGatherCompanySettingsModel(clientId))
    dispatch(getOrganizerData(clientId, (data: any) => {
      setDocument(data);

      if (data && data.clients && data.clients.length > 1) {
        const client = data.clients.find((x: any) => x.clientGuid === clientId);
        if (client && !client.hideWarning) {
          !isClientView && !isPreview && setShowAttentionModal(true);
          setIsAttentionModalChecked(false);
        }
      }
    }));

    dispatch(getFormControlsJson(clientId, (data: any) => {
      setControlsData(data);
    }));

    // Get Uploaded Source Documents
    dispatch(getUploadedSourceDocuments(clientId, (data: any) => {
      setUploadedFiles(data);
    }));

    dispatch(requestUploadedDocumentStatus(clientId));
  }, []);

  useEffect(() => {
    document && document.formGroups && document.formGroups.length && setTempFormDataState([...document.formGroups]);
    if(document?.clients){
      let client = document.clients.find(x => x.clientGuid === clientId);
      if(client?.clientType === ClientType.Taxpayer){
        setSpouseInfo(clientState.spouse);
        setSpouseEmail(clientState.spouse.emailAddress);
      }
      else{
        setSpouseInfo(clientState.taxpayer);
        setSpouseEmail(clientState.taxpayer.emailAddress);
      }
    }
  }, [document]);

  const getUploadedFile = () => {
    dispatch(getUploadedSourceDocuments(clientId, (data: any) => {
      setUploadedFiles(data);
    }));
  }

  const handleClickToUpload = () => {
    if (isPreview || isClientView) {
      Toaster.warn(PreviewPageConstant.UploadedRestrictedMessage);
      return;
    }
    setShowUploadModal(true);
  };

  const handleTaxPayerNotesChange = (e: any) => {
    e.target.value =="" ? setDisableNoteSave(true): setDisableNoteSave(false);
    setCurrentForm({ ...currentForm, message: e.target.value });
  };

  const handleSaveTaxPayerNotes = () => {
    dispatch(saveTaxPayerNotes(clientId, currentForm?.formId, currentForm?.message, currentForm.individualFilePageNo, (data: any) => {

      setDocument({
        ...document,
        formGroups: document.formGroups?.map((formGroup: any) => {
          return {
            ...formGroup,
            forms: formGroup.forms.map((form: any) => {
              if (form.formId === currentForm?.formId) {
                return {
                  ...form,
                  message: currentForm?.message
                }
              }
              return form;
            })
          }
        })
      })
      setDisableNoteSave(true)
    }));
  }

  const handleDeleteTaxPayerNotes = () => {
    dispatch(deleteTaxPayerNotes(clientId, currentForm?.formId, (data: any) => {
      setCurrentForm({ ...currentForm, message: '' });
      setDisableNoteSave(true);
      document && document.formGroups && setDocument({
        ...document,
        formGroups: document.formGroups.map((formGroup: any) => {
          return {
            ...formGroup,
            forms: formGroup.forms.map((form: any) => {
              if (form.formId === currentForm?.formId) {
                return {
                  ...form,
                  message: ''
                }
              }
              return form;
            })
          }
        })
      })
    }));
  }

  const handleSkipAndFinishForPreview = (isSave: boolean) => {
    if (isPreview) {
      if (isSave) {
        navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`);
      } else {
        navigate(nextNavigation.nextURL);
      }

      return true;
    }
    if (isClientView) {
      navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`);
      return true;
    }
    return false;
  }

  const handlePreviewSourceDocument = (document: any, categoryName?: string) => {
    setSelectedFile({ ...document });
    dispatch(getSourceDocumentPreview(clientId, document?.id ?? 0));
    setShowPreviewModal(true);
  }
  const handleDeleteSourceDocument = (file: any) => {
    setUploadedFiles(uploadedFiles.filter((x: any) => x.id !== file.id))

    dispatch(deleteUploadedDocument(clientId, file.id, () => {
      Toaster.success("Document deleted successfully.");
    },
      (errorDescription) => {
        Toaster.error(errorDescription);
      }))
  }
  const rightPanelContent = () => {
    const currentFormFiles = uploadedFiles?.filter((x: any) => x.formId === currentForm?.formId);
    const isPreviewMode = isPreview || isClientView;
    return (
      <div className='organizer-right-panel'>
        {currentForm?.enabledSourceDocument && isSourceDocumentSettingEnabled && <div className='upload-header-section'>
          <h6>{OrganizerConstant.ProvideSourceDocuments}</h6>
          {currentForm.bookmark
            ? <p>Please upload all {currentForm.bookmark.slice(-1) == '.' 
                ? currentForm.bookmark.substring(0, currentForm.bookmark.length - 1).toLowerCase() 
                : currentForm.bookmark.toLowerCase()} forms</p>
            : <p>{OrganizerConstant.SourceDocumentsTypeText}</p>}
          <div className='upload-btn-container'>
            <Button className='ss-btn-primary'
              onClick={handleClickToUpload}
              disabled={isSourceDocumentCompeleted}>
              {ButtonConstant.ClickToUpload}
            </Button>
            <span>
              {currentFormFiles?.length || 0} {OrganizerConstant.DocumentsUploaded}
            </span>
          </div>
        </div>}
        {
          currentFormFiles?.length > 0 && currentForm?.enabledSourceDocument && <div className='uploaded-files-list-container'>
            {
              currentFormFiles.map((file: any, index: number) => {
                return (
                  <div key={index} className='uploaded-file-item'>
                    <span className='file-name'>{file.fileName}</span>
                    <div>
                      <button title={ isPreviewMode ? PreviewConstants.PreviewNotAllowed :''} 
                      disabled={isPreviewMode}
                      className={`button-pointer ${isPreviewMode ? 'pointer-disabled' : 'pointer-enabled'}`}
                      onClick={() => { 
                        if(isPreviewMode) return;
                        handlePreviewSourceDocument(file)
                      }}><PreviewIcon/></button>
                      <button title={ isPreviewMode ? PreviewConstants.DeleteNotAllowed :''} 
                      disabled={isPreviewMode}
                      className={`button-pointer ${isPreviewMode ? 'pointer-disabled' : 'pointer-enabled'}`}
                      onClick={() => {
                        if(isPreviewMode) return;
                        handleDeleteSourceDocument(file)}}><DeleteIcon /></button>
                    </div>
                  </div>
                )
              })
            }
          </div>}

        <div className='notes-container'>
          <h6>{OrganizerConstant.NotesToTaxPreparer}</h6>
          <textarea
            placeholder={OrganizerConstant.NotesForTaxPreparer}
            key={currentForm?.formId}
            onChange={handleTaxPayerNotesChange}
            value={currentForm?.message}
            disabled={isPreview || isClientView}
          ></textarea>

          <div className='notes-btn-container' style={{ flexDirection: currentForm?.message?.length > 0 ? "row" : "row-reverse" }}>
            {currentForm?.message?.length > 0 && <span onClick={handleDeleteTaxPayerNotes}>
              <DeleteIcon />
            </span>}
            <ButtonComponent onClick={handleSaveTaxPayerNotes}
              disabled={isPreview || isClientView || disableNoteSave}>
              {ButtonConstant.Save}
            </ButtonComponent>
          </div>
        </div>
      </div>
    )
  }

  const onSaveClick = () => {
    if (handleSkipAndFinishForPreview(true)) return;
    const saveOrganizerPayload: IOrganizerSaveModel = {
      formGroup: document?.formGroups[0],
      isReviewed: false,
      signature: ""
    }

    dispatch(saveOrganizer(clientId, saveOrganizerPayload, (data: any) => {
      setTimeout(() => {
        navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`)
      }, 2000)

    }));
  }

  const showSpousePopup = () => {
    const clientInfo = document.clients.find((x: any) => x.clientGuid === clientId);
    return (clientInfo.signingOrder === 1 && spouseInfo && spouseInfo.id > 0
      && spouseInfo.isDeceased === false);
  }

  const onFinishClick = () => {
    if (handleSkipAndFinishForPreview(false)) return;
    if (spouseInfo && spouseInfo.clientGuid &&
      !spouseInfo.isDeceased &&
      spouseInfo.reviewStatus === ReviewStatus.PendingReview ||
      spouseInfo.reviewStatus === ReviewStatus.Reviewed) {
      setShowCompleteModal(true);
    }
    else if (spouseInfo && spouseInfo.clientGuid && !spouseInfo.isDeceased && spouseInfo.signingOrder != 1) {
      setShowOrgCompleteModal(true);
    }
    else {
      setShowCompleteModal(true);
    }
  }

  const handleFinishOrganizer = () => {
    const finishOrganizerPayload: IOrganizerSaveModel = {
      formGroup: document?.formGroups[0],
      isReviewed: false,
      signature: ""
    }
    setShowOrgCompleteModal(false);
    setShowCompleteModal(false);
    if (!(spouseInfo.reviewStatus === ReviewStatus.Reviewed)) {
      dispatch(reviewOrganizer(clientId, ReviewStatus.Ignored));
    }
    dispatch(finishOrganizer(clientId, finishOrganizerPayload, (data: any) => {
      if (nextNavigation.nextURL) {
        navigate(nextNavigation.nextURL);
      } else {
        navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`)
      }
    }));
  }

  const handleSendForReview = () => {
    if (showSpousePopup()) {
      setShowOrgCompleteModal(false);
      setSpouseEmail(spouseInfo.emailAddress);
      setShowSpouseRequiredModal(true);
    }
    else {
      sendForReview();
    }
  }

  const saveOrganizerData = () => {
    const saveOrganizerPayload: IOrganizerSaveModel = {
      formGroup: document.formGroups[0],
      isReviewed: false,
      signature: ""
    }
    dispatch(saveOrganizer(clientId, saveOrganizerPayload, (data: any) => { }));
  }

  const sendForReview = () => {
    saveOrganizerData();
    setShowOrgCompleteModal(false);
    dispatch(sendOrganizerForReview(clientId, (data: any) => {
      navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`)
    }));
  }

  const handleHideWarningSubmit = () => {
    if (isAttentionModalChecked) {
      dispatch(hideWarning(clientId, (data: any) => {
        setShowAttentionModal(false);
      }));
    }
  }

  const handleReviewMissingInformation = () => {
    if (!(spouseInfo.reviewStatus === ReviewStatus.Reviewed)) {
      dispatch(reviewOrganizer(clientId, ReviewStatus.Reviewed));
    }
    setShowCompleteModal(false);
  }

  return (
    <div className='organizer-container'>
      <Header type={HeaderType.WITHSTEP} hideContact={true} />
      <div className='pdf-wrapper'>
        <AwesomePdfViewer
          hasSignatureControls={false}
          pageType={GroupType.Organizer}
          formGroups={document?.formGroups}
          controlsData={controlsData}
          documentUrl={document?.organizerUrl}
          tempFormGroupState={tempFormGroupState}
          setTempFormDataState={setTempFormDataState}
          additionalDocuments={[]}
          onControlUpdate={() => { }}
          onDocumentUpdate={() => { }}
          onSaveToolTip={() => { }}
          setCurrentForm={setCurrentForm}
          handleAddDocumentClick={() => { }}
          leftPanelTabs={[{ caption: "Bookmarks", type: AccordianItemType.Bookmark },
          ]}
          rightPanelContent={rightPanelContent()}
          hasRightPanel={true}
          isReadOnlyMode={isPreview || isClientView}
          isSourceDocumentEnabled={isSourceDocumentSettingEnabled} />
      </div>
      <Footer
        showSaveBtn
        nextDisabled={false}
        onSave={onSaveClick}
        onFinish={onFinishClick}
        isLast={nextNavigation.isLast} />
      <MemoizedSignalRWebSocket client_id={clientId} />
      <FooterComponent />

      {
        // PDF Preview Modal
        <PreviewUplodedDRLFileModal
          customClass=""
          fileId={selectedFile?.id}
          documentType={selectedFile?.categoryName ?? ""}
          fileName={selectedFile?.fileName ?? ""}
          fileType={previewDocumentData.extension?.slice(1)}
          onHidePDFPreview={() => setShowPreviewModal(false)}
          showPDFPreview={showPreviewModal}
          url={previewDocumentData.path}
          organizerStep={true}
          documentId={selectedFile?.id}
          isStepCompleted={false}
        />
      }
      {
        // Organizer complete Modal
        <CustomModal
          className='attention-modal'
          show={showOrgCompleteModal}
          cancelButtonName='Send for Review'
          confirmButtonName='Complete Organizer'
          onHide={() => { setShowOrgCompleteModal(false); }}
          title="Organizer Complete"
          onCancelButtonClick={handleSendForReview}
          onSubmit={() => { handleFinishOrganizer(); }}
        >
          <div>
            <p>{OrganizerConstant.CompleteOrganizerConfirmation1}</p>
            <p>{OrganizerConstant.CompleteOrganizerConfirmation2}</p>
          </div>
        </CustomModal>
      }
      {
        // Organizer complete without spouse Modal
        <CustomModal
          key={2}
          className='attention-modal'
          show={showCompleteModal}
          cancelButtonName='Review Missing Information'
          confirmButtonName='Finish'
          onHide={() => { setShowCompleteModal(false); }}
          title="Confirm Completion"
          onCancelButtonClick={() => { handleReviewMissingInformation(); }}
          onSubmit={() => { handleFinishOrganizer(); }}
        >
          <div>
            <p>{OrganizerConstant.CompleteOrganizerConfirmation1}</p>
          </div>
        </CustomModal>
      }
      {
        // File Upload Modal
        currentForm && <FileUploadModal
          show={showUploadModal}
          onHide={() => { setShowUploadModal(false); }}
          onSubmit={() => { setShowUploadModal(false); }}
          setShowModal={setShowUploadModal}
          getUploadedFile={getUploadedFile}
          currentForm={currentForm}
        />
      }
      {
        // Attention Modal
        <CustomModal
          className='attention-modal'
          show={showAttentionModal}
          onHide={() => { setShowAttentionModal(false); setIsAttentionModalChecked(!isAttentionModalChecked); }}
          title="Attention!"
          onSubmit={handleHideWarningSubmit}
          disableConfirmButton={!isAttentionModalChecked}
          additionalFooterElement={
            <div>
              <CheckBoxComponent text='Do not show this message again'
                checked={isAttentionModalChecked}
                onChange={() => { setIsAttentionModalChecked(!isAttentionModalChecked); }}
                id="checkbox"
              />
            </div>
          }
        >
          <div>
            <p>
              {OrganizerConstant.AttentionMessage}
            </p>
          </div>
        </CustomModal>
      }
      {
        // Spouse Required Modal
        <CustomModal
          className='spouse-required-modal'
          show={showSpouseRequiredModal}
          onHide={() => { setShowSpouseRequiredModal(false); }}
          title={OrganizerConstant.SpouseRequiredModalTitle}
          confirmButtonName={ButtonConstant.SaveAndContinue}
          disableConfirmButton={spouseEmail?.trim().length === 0}
          onSubmit={() => {
            let spouse: ClientModel = { ...spouseInfo, emailAddress: spouseEmail };
            if (isValidEmailAddress(spouseEmail)) {
              dispatch(
                saveClientDetails(
                  clientId,
                  spouse,
                  () => {
                    sendForReview();
                    setShowSpouseRequiredModal(false);
                  }));
            }
          }}
        >
          <div>
            <p>{OrganizerConstant.SpouseRequiredModalText}</p>

            <div className="form-group">
              <label>Spouse’s Name</label>
              <span>{spouseInfo?.name}</span>
            </div>
            <div className="form-group">
              <label htmlFor="spouseEmail">Email <label className={"emailaddress-required-indicator"}>*</label></label>
              <input type="email"
                id="spouseEmail"
                value={spouseEmail}
                onChange={(e) => { setSpouseEmail(e.target.value.trim()); }} />
            </div>
          </div>
        </CustomModal>
      }
    </div>
  );
};

export default OrganizerComponent;
