import { ClientType, SourceDocumentStatus, UploadStatus } from "../enum";

export interface IDocumentRequest {
    documentId: number;
    id: number;
    isMapped: boolean;
    name: string;
    notApplicable: boolean;
    sourceDocuments: {
        fileName: string;
        pageNumber: number;
        documentId: number;
    }[];
}

export interface IUnidentifiedDocument {
    name: string;
    isMapped: boolean;
    documentId: number;
    type: string;
    fileName: string;
}

export interface ICategory {
    categoryId: number;
    categoryName: string;
    documents: IDocumentRequest[];
    unRecognizedDocuments: IUnidentifiedDocument[];
}

export interface IDocumentRequestName {
    id: number;
    name: string;
    type: string;
    documentRequestFormTypeId: number;
    documentRequestTypeId: number;
    categoryName: string;
    notApplicable: boolean;
}

export interface DocumentRequestListState {
    documentRequestList: ICategory[]
    documentRequestNames: IDocumentRequestName[],
    uploadedDocuments: UploadedFile[],
    isDRLExist: boolean,
    isLoading: boolean
}

export const initialDocumentRequestListState: DocumentRequestListState = {
    documentRequestList: [],
    documentRequestNames: [],
    uploadedDocuments: [],
    isDRLExist: false,
    isLoading: false
}

export interface GatherDocumentRequest {
    id: number | null;
    name: string | null;
    documentId: number | null;
    fileName: string | null;
    isMapped: boolean;
    type: string | null;
    categoryId: number | null;
    categoryName: string | null;
    isLoading: boolean | null;
}

export const initialGatherDocumentRequest: GatherDocumentRequest =
{
    id: 0,
    name: "",
    documentId: 0,
    fileName: "",
    isMapped: false,
    type: "",
    categoryId: 0,
    categoryName: "",
    isLoading: false
}

export interface UploadedFile {
    id: number;
    uploadedOn: Date;
    name: string;
    file: any;
    status: UploadStatus;
    uploadPercentage: number;
    documentGuid: string;
    taxYear: number;
    documentId: number
}

export interface ConvertedUploadedFileModel {
    isFileSizeLimitExceeded: boolean;
    isUnsupportedFileExtension: boolean;
    counterFileSizeLimitFailed: number;
    counterUnsupportedFileExtensionFailed: number;
    uploadedFiles: UploadedFile[]
}

export class UploadedDocument {
    id: number;
    fileName: string;
    clientId: number;
    uploadedOn: Date;
    documentId: number;
    formId?: number;
    documentGuid: string;
    taxYear: number;
    fileSize: string;
    gatherDocumentRequestId?: number;
    constructor(id: number,
        fileName: string,
        clientId: number,
        uploadedOn: Date,
        documentId: number,
        documentGuid: string,
        taxYear: number,
        fileSize: string,
        formId?: number,
        gatherDocumentRequestId?: number
    ) {
        this.id = id;
        this.fileName = fileName;
        this.clientId = clientId;
        this.uploadedOn = uploadedOn;
        this.documentId = documentId;
        this.formId = formId;
        this.documentGuid = documentGuid;
        this.taxYear = taxYear;
        this.fileSize = fileSize;
        this.gatherDocumentRequestId = gatherDocumentRequestId;
    }

    public static createNullObject(): UploadedDocument {
        return new UploadedDocument(0, "", 0, new Date(), 0, "", 0, "", 0);
    }

    public static create(fileName: string, fileSize: string, formId?: number, fileGuid: string = ""): UploadedDocument {
        return new UploadedDocument(0, fileName, 0, new Date(), 0, fileGuid, 0, fileSize, formId);
    }
}

export interface UploadedDocumentStatus {
    id: number;
    documentId: number;
    status: SourceDocumentStatus;
    createdOn: Date | undefined;
    updatedOn: Date | undefined;
    actedBy: ClientType;
    isSourceDocumentCompleted: boolean;
    isLoading: boolean | null;
}

export const initialUploadedDocumentStatus: UploadedDocumentStatus = {
    actedBy: ClientType.Undefined,
    createdOn: new Date(),
    updatedOn: new Date(),
    documentId: 0,
    id: 0,
    status: SourceDocumentStatus.None,
    isSourceDocumentCompleted: false,
    isLoading: false
}
export interface ISourceDocumentUploadState {
    uploadedDocumentStatus: UploadedDocumentStatus;
    previewDocumentData: IPreviewFileData;
    isLoading: boolean;
}
export const initialSourceDocumentState: ISourceDocumentUploadState = {
    uploadedDocumentStatus: initialUploadedDocumentStatus,
    previewDocumentData: { path: "", fileName: "", extension: "" },
    isLoading: false
}

export const ConflictErrorMessage = "Source document upload has been completed.";

export interface IPreviewFileData {
    path: string;
    fileName: string;
    extension: string;
}

export interface IDeleteFileMappingRequest {
    drlId: number;
    sourceDocumentId: number;
}