import React, { FC, useEffect, useState } from "react";
import Header from "../../common/components/layout/Header";
import { GatherStepStatus, HeaderType } from "../../common/enum";
import { FooterComponent } from "cp-common-ui-components";
import { UploadDRLDocuments } from "./UploadDRLDocument";
import DocumentRequestUploadProgressbar from "./DocumentRequestUploadProgressbar";
import "./index.scss";
import { Col, Row } from "react-bootstrap";
import DocumentRequestList, { IDocumentRequestList } from "./DocumentRequestList";
import DrlFooter from "./DrlFooter";
import FinishDocumentUploadModal from "./FinishDocumentUploadModal";
import { useAppDispatch, useAppSelector } from "../../common/hooks/redux-hooks";
import { ICategory, IDocumentRequest } from "../../common/model/document-request-model";
import { isMobile } from "../../helper/HelperFunctions";
import { useUpdateCurrentStep } from "common/hooks/step-hook";
import { useClientId } from "../../common/hooks/client-hook";
import { StepName } from "../../helper/Constants";
import { AppState } from "store";
import { gatherStepsWithStatus } from "common/model/gatherStepsWithStatus";
import { getGatherMetaData } from "store/services/esign";
import { setNoticePopup } from "store/slices/common-slice";
import { useCompleted } from "common/hooks/useCompletedHook";
import ReMapDrlModal from "./ReMapDrlModal";
import useOtherCategoryId from "common/hooks/useOtherCategoryId";
import { useNavigate } from "react-router-dom";
import { TAXPAYER } from "route/paths";
import { getGatherCompanySettingsModel } from "store/services/companySettings";
import { GatherCompanySettingsModel } from "common/model/company-settings";
import { MemoizedSignalRWebSocket } from "common/components/signalR/SignalRWebSocket";

const DRLPage: FC = () => {
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);
  const { documentRequestList } = useAppSelector((state) => state.documentRequestReducer);
  const { stepsWithStatus } = useAppSelector((state: AppState) => state.gatherStepsWithStatusReducer);
  const isSourceDocumentSettingEnabled: boolean = useAppSelector((state: AppState) => state.companySettings?.sourceDocumentSettings?.enabledSourceDocuments);

  const dispatch = useAppDispatch();
  const [clientId] = useClientId();
  const [isDocumentListEmptySD, setIsDocumentListEmptySD] = useState<boolean>(false);
  const unrecognizedDocumentCount = documentRequestList.filter((item: ICategory) => item.categoryId === null)[0]?.documents.length;

  const isClientView = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isClientView);
  const isPreview = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isPreview);
  const otherCategoryId = useOtherCategoryId();

  const navigate = useNavigate();

  useEffect(() => {
    const isFirstStep = stepsWithStatus.findIndex((step: gatherStepsWithStatus) => step.name === StepName.Upload) === 0
    isFirstStep && dispatch(setNoticePopup(true));
  }, []);

  useEffect(() => {
    dispatch(getGatherCompanySettingsModel(clientId, (settingsData: GatherCompanySettingsModel) => {
      if (!settingsData.sourceDocumentSettings.enabledSourceDocuments) {
        navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`);
      }
    }));
  }, [isSourceDocumentSettingEnabled])

  const handleEmptySDStateChange = (isEmptySD: boolean) => {
    setIsDocumentListEmptySD(isEmptySD);
  };

  const getTotalUncategorizedDocuments = () => {
    return documentRequestList.reduce((acc: number, category: IDocumentRequestList) => {
      return category.categoryId === null ? acc + category.documents.length : acc;
    }, 0);
  };
  const getTotalCategorizedDocuments = () => {
    return documentRequestList.reduce((acc: number, category: IDocumentRequestList) => {
      return category.categoryId !== null ? acc + category.documents.length + category.unRecognizedDocuments.length : acc;
    }, 0);
  };

  const recognizedDocumentCount = getTotalCategorizedDocuments();
  const totalUncategarizedCount = getTotalUncategorizedDocuments();
  const isAllDocumentsUploaded = documentRequestList.every((category: ICategory) =>
    category.documents.every((doc: IDocumentRequest) => doc.isMapped)
  );
  useUpdateCurrentStep(StepName.Upload);

  const isCurrentStepCompleted = () =>
    stepsWithStatus.find((step: gatherStepsWithStatus) => !!step.current)?.status === GatherStepStatus.Done;

  useEffect(() => {
    dispatch(getGatherMetaData(clientId));
  }, []);

  useEffect(()=>{
    const totalDocumentRequest = getTotalUncategorizedDocuments() + getMappedDocumentRequest();
    if(!totalDocumentRequest) handleEmptySDStateChange(true)
  },[documentRequestList]);

  useEffect(()=>{
    const totalDocumentRequest = totalUncategarizedCount + getMappedDocumentRequest()
    if(!totalDocumentRequest) 
      {
        handleEmptySDStateChange(true)
      }
    else{
      handleEmptySDStateChange(false)
    }
  },[totalUncategarizedCount,recognizedDocumentCount])

  useCompleted(StepName.Upload)

  const getMappedDocumentRequest = (): number => {
    return documentRequestList.reduce((total: any, value: ICategory) => {
        // Consider both isMapped and notApplicable for uploaded documents
        return total + value.documents.filter((item) => item.isMapped || item.notApplicable).length + value.unRecognizedDocuments.length;
    }, 0);
};

  return (
    <div> 
      <Header type={HeaderType.WITHSTEP} hideContact={true} />
      <div className={`container-wrapper drl-container ${(!recognizedDocumentCount && !unrecognizedDocumentCount) ? "without-progress-bar" : ""}`}>
        <Row className="top-section">
          <Col className="d-flex align-items-center">
            <UploadDRLDocuments
              clientId={clientId}
              isCompleted={isAllDocumentsUploaded}
              disabled={isCurrentStepCompleted() || isPreview || isClientView}
              isPreviewOrClientViewMode={isPreview || isClientView}
            />
          </Col>
          {recognizedDocumentCount || unrecognizedDocumentCount ? <Col>
            <DocumentRequestUploadProgressbar/>
          </Col> : <></>}
        </Row>
        <DocumentRequestList isStepCompleted={isCurrentStepCompleted()} />
        <DrlFooter onFinishClick={() => setShowConfirmationModal(true)} isCompleted={isCurrentStepCompleted()} isPreview={isPreview || isClientView} isDocumentListEmptySD={isDocumentListEmptySD} />
        <FinishDocumentUploadModal show={showConfirmationModal} setShow={setShowConfirmationModal} />
      </div>
      <MemoizedSignalRWebSocket client_id={clientId} />
      {!isMobile() && <FooterComponent />}
      <ReMapDrlModal />
      </div>
  );
};

export default DRLPage;
