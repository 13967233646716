import React, { FC, useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { ButtonComponent } from "cp-common-ui-components";
import { getCategoryList, getDocumentRequestList, updateDocumentType } from "../../../store/services/documentRequestList";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { ICategory, IDocumentRequest, IDocumentRequestName } from "../../model/document-request-model";
import { Toaster } from '../../../common/components/Toasts';
import useOtherCategoryId from "../../hooks/useOtherCategoryId";
import { useClientId } from "../../hooks/client-hook";
import { GatherUploadedDocuments } from "helper/Constants";

interface IEditDocuemntModalProps {
    show: boolean;
    onHide: () => void;
    selectedDocument: any;
}

const EditDocumentModal: FC<IEditDocuemntModalProps> = ({ show, onHide, selectedDocument }) => {
    const [enableOthers, setEnableOthers] = useState<boolean>(false);
    const [selectedTypes, setSelectedTypes] = useState<number[]>([]);
    const [selectedOtherType, setSelectedOtherType] = useState<number>(-1);
    const [customLabel, setCustomLabel] = useState<string>("");
    const [selectedID, setSelectedID] = useState<number>(0);
    const { documentRequestNames, documentRequestList } = useAppSelector(state => state.documentRequestReducer);
    const otherCategoryId = useOtherCategoryId();
    const dispatch = useAppDispatch();
    const [clientId] = useClientId();
    const getFileName = () => {
        const category = documentRequestList.find((list: ICategory) => list.categoryId === selectedDocument?.categoryId);
        return category?.documents.find((file: IDocumentRequest) => file.documentId === selectedDocument.documentId)?.fileName ?? "";
    };

    const handleSubmit = () => {
        const payload = {
            documentId: selectedDocument.documentId,
            name: enableOthers ? customLabel || "" : selectedDocument.name,
            gatherDocumentRequestIds: enableOthers ? [selectedDocument.documentId] : selectedTypes.length > 0 ? selectedTypes : [selectedOtherType],
            isOtherOptionEnabled: enableOthers,
            categoryId: enableOthers ? otherCategoryId : selectedDocument?.categoryId,
            pageNumber: 0
        }
        handleClose();
        dispatch(updateDocumentType(
            clientId,
            payload,
            () => {
                Toaster.success(GatherUploadedDocuments.DocumentRequestTypeUpdated);
                dispatch(getCategoryList(clientId));
                dispatch(getDocumentRequestList(clientId));
            },
            (errorDescription) => {
                Toaster.warn(errorDescription);
            }
        ))
    };
    const handleClose = () => {
        onHide();
        setCustomLabel("");
        setSelectedOtherType(-1);
        setSelectedTypes([]);
    };
    const handleTypeChange = (id: number) => {
        setSelectedTypes((prev) => {
            if (selectedTypes.includes(id)) {
                return prev.filter((type) => type !== id);
            } else {
                return [...prev, id]
            }
        });
        setCustomLabel("");
        setSelectedOtherType(-1);
    };

    useEffect(() => {
        documentRequestList.forEach((document: any) => {
            if (document.categoryName === "Other") {
                document.documents.forEach((element: any) => {
                    element.sourceDocuments.forEach((sourceDocument: any) => {
                        if (sourceDocument.documentId === selectedDocument?.documentId) {
                            setSelectedOtherType(element.id);
                            setSelectedID(element.id);
                        }
                    })
                })
            }
            else {
                document.documents.forEach((element: any) => {
                    element.sourceDocuments.forEach((sourceDocument: any) => {
                        if (sourceDocument.documentId === selectedDocument?.documentId) {
                            !selectedTypes.includes(element.id) && element.id && setSelectedTypes((prev) => {
                                return [...prev, element.id]
                            });
                        }
                    })
                })
            }
        })
    }, [selectedDocument]);

    useEffect(() => {
        if (selectedOtherType === 0) {
            setEnableOthers(true);
        }
        else {
            setEnableOthers(false);
        }
    })
    const handleButtonDisabled = () => !(!!enableOthers && !!customLabel) && ((selectedOtherType === 0 || selectedOtherType === -1) && selectedTypes.length === 0);
    return <Modal className="edit-document-modal white-modal" show={show} onHide={handleClose} >
        <Modal.Header closeButton>
            <Modal.Title>Edit Document Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <div className='file-name ellipsis' title={getFileName()}>{getFileName()}</div>
                {documentRequestNames.filter((file: IDocumentRequestName) => file.categoryName !== "Other").map((file: IDocumentRequestName, index: number) => {
                    return <div key={index}>
                        <Form.Check type="checkbox" name="file-type-group" checked={selectedTypes.includes(file.id)} label={file.name} title={file.name} onChange={() => handleTypeChange(file.id)} />
                    </div>;
                })}
                {documentRequestNames.filter((file: IDocumentRequestName) => file.categoryName === "Other").map((file: IDocumentRequestName, index: number) => {
                    return <div key={index}>
                        {index === 0 && <hr />}
                        <Form.Check type="radio"
                            name="file-type-group"
                            label={file.name}
                            checked={selectedOtherType === file.id}
                            onChange={() => {
                                setSelectedTypes([]);
                                setSelectedOtherType(file.id);
                            }
                            } />
                    </div>
                })}
                <div className="title">Uncategorized</div>
                <div className="d-flex margin-top-10-px">
                    <Form.Check type="radio"
                        name="file-type-group"
                        label="Other"
                        checked={selectedOtherType === 0}
                        onChange={(e) => {
                            setSelectedTypes([]);
                            setSelectedOtherType(0)
                        }
                        } />
                    <Form.Control value={customLabel} onChange={(e) => setCustomLabel(e.target.value)} disabled={!enableOthers} className="other-label margin-left-10-px" size="sm" />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <ButtonComponent variant="outline-tertiary" onClick={handleClose}>Cancel</ButtonComponent>
            <ButtonComponent disabled={handleButtonDisabled()} onClick={handleSubmit}>Update</ButtonComponent>
        </Modal.Footer>
    </Modal>
};

export default EditDocumentModal;