import { DRLPredictionStatus } from "common/enum";
import { Guid } from "./common";

export interface IDRLPredictionRequestState {
    drlPredictionRequests: IDRLPredictionRequest[];
}

export interface IDRLPredictionRequest {
    gatherId: number;
    clientGuid: string;
    documentGuids: IDocumentDRLPredictionStatus[];
    requestId: string;
    requestStatus: DRLPredictionRequestStatus;
    updateDRLPredictionStatus(documentGuid: string, status: DRLPredictionStatus): void;
    isDocumentExists(documentGuid: string): boolean;
    addDocumentGuid(documentGuid: string, status: DRLPredictionStatus): void;
    isAllDocumentsProcessed(): boolean;
    isAllDocumentsFailed(): boolean;
    clearDocumentGuids(): void;
    updateRequestStatus(status: DRLPredictionRequestStatus): void;
}

export interface IDocumentDRLPredictionStatus {
    status: DRLPredictionStatus;
    documentGuid: string;
}

export enum DRLPredictionRequestStatus {
    New,
    InProgress,
    Success,
    Failed,
    Completed
}

export const initialDRLPredictionRequest: IDRLPredictionRequest = {
    gatherId: 0,
    clientGuid: "",
    documentGuids: [],
    requestId: Guid.newGuid().toString(),
    requestStatus: DRLPredictionRequestStatus.New,
    updateDRLPredictionStatus: (documentGuid: string, status: DRLPredictionStatus): void => { },
    isDocumentExists: (documentGuid: string): boolean => false,
    addDocumentGuid: (documentGuid: string, status: DRLPredictionStatus): void => { },
    isAllDocumentsProcessed: (): boolean => false,
    isAllDocumentsFailed: (): boolean => false,
    clearDocumentGuids: (): void => { },
    updateRequestStatus: (status: DRLPredictionRequestStatus): void => { }
}

export class DRLPredictionRequest implements IDRLPredictionRequest {
    gatherId: number;
    clientGuid: string;
    documentGuids: IDocumentDRLPredictionStatus[];
    requestId: string;
    requestStatus: DRLPredictionRequestStatus;

    constructor(gatherId: number, clientGuid: string) {
        this.gatherId = gatherId;
        this.clientGuid = clientGuid;
        this.documentGuids = [];
        this.requestId = Guid.newGuid().toString();
        this.requestStatus = DRLPredictionRequestStatus.New;
    }

    updateDRLPredictionStatus(documentGuid: string, status: DRLPredictionStatus): void {
        const document = this.documentGuids.find(d => d.documentGuid === documentGuid);
        if (document) {
            document.status = status;
        }
    }

    isDocumentExists(documentGuid: string): boolean {
        return this.documentGuids.some(d => d.documentGuid === documentGuid);
    }

    addDocumentGuid(documentGuid: string, status: DRLPredictionStatus): void {
        this.documentGuids.push({ documentGuid, status });
    }

    isAllDocumentsProcessed(): boolean {
        return this.requestStatus === DRLPredictionRequestStatus.InProgress && this.documentGuids.every(d => d.status === DRLPredictionStatus.Success || d.status === DRLPredictionStatus.Failed);
    }

    isAllDocumentsFailed(): boolean {
        return this.requestStatus === DRLPredictionRequestStatus.InProgress && this.documentGuids.every(d => d.status === DRLPredictionStatus.Failed);
    }

    clearDocumentGuids(): void {
        this.documentGuids = [];
    }

    updateRequestStatus(status: DRLPredictionRequestStatus): void {
        this.requestStatus = status;
    }

}