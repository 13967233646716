import React, { FC, useEffect, useState } from "react";
import { RadioButtonIcon, RadioButtonTickedIcon } from "../../assets/svg/SVGIconsCollection";
import { GatherUploadedDocuments } from "../../helper/Constants";
import { IDocumentRequestName } from "../../common/model/document-request-model";
import { useAppSelector } from "../../common/hooks/redux-hooks";
import { ClientModel } from "../../common/model/clients";
import { AppState } from "../../store";
import "./printStyles.scss";
export interface IDocumentRequestList {
  categoryId: number;
  categoryName: string;
  documents: {
    id: number;
    name: string;
    isMapped: boolean;
    documentId?: number;
    type?: string;
    fileName?: string;
    notApplicable?: boolean;
  }[];
  unRecognizedDocuments: {
    id: number;
    name: string;
    isMapped: boolean;
    documentId?: number;
    type?: string;
    fileName?: string;
  }[];
}

const PrintableDocReqList: FC = () => {
  const [printDocumentRequestList, setList] = useState<IDocumentRequestList[]>([]);
  const { documentRequestList, documentRequestNames } = useAppSelector((state) => state.documentRequestReducer);
  const taxpayer: ClientModel = useAppSelector((state: AppState) => state.clientReducer?.taxpayer);

  const spouse: ClientModel = useAppSelector((state: AppState) => state.clientReducer?.spouse);
  const companyName = useAppSelector((state: AppState) => state.coverPageReducer?.companyName);
  const documentRequestCount = documentRequestNames.filter(
    (item: IDocumentRequestName) => !!item.id && item.type !== GatherUploadedDocuments.NotAvailableFormType && !item.notApplicable
  ).length;

  const getTotalCategorizedDocuments = () => {
    return documentRequestList.reduce((acc: number, category: IDocumentRequestList) => {
      return category.categoryId !== null ? acc + category.documents.length + category.unRecognizedDocuments.length : acc;
    }, 0);
  };

  const hideCategoryCondition = (category: IDocumentRequestList) => {
    const condition =
      category &&
      category.documents &&
      category.documents.length > 0 && //Condition to check system identified files
      category.documents.every((document) => document.notApplicable === true);

    return !condition;
  };
  const getDocumentsForPrint = () => {
    const docList: IDocumentRequestList[] = [];
    documentRequestList.forEach((category) => {
      if (
        category.categoryId !== null && //Condition to check Unrecognized files
        hideCategoryCondition(category) &&
        category.documents.length > 0 &&
        category.documents.length < 30
      ) {
        docList.push(category);
      } else if (
        category.categoryId !== null && //Condition to check Unrecognized files
        hideCategoryCondition(category) &&
        category.documents.length > 30
      ) {
        const half = Math.ceil(category.documents.length / 2);

        const newCategory1 = category.documents.slice(0, half);
        const newCategory2 = category.documents.slice(half);
        const docList1: IDocumentRequestList = {
          ...category,
          documents: [...newCategory1]
        };
        const docList2: IDocumentRequestList = {
          ...category,
          categoryName: category.categoryName + "(CONT.)",
          documents: [...newCategory2]
        };
        docList.push(docList1);
        docList.push(docList2);
      }
    });
   setList(docList);
  };

  useEffect(() => {
    getDocumentsForPrint();
  }, [documentRequestList]);

  return (
    <>
      <div className="print-header">
        <span>{companyName}</span>
      </div>
      <div className="printable-body">
        {getTotalCategorizedDocuments() ? (
          <div className="print-categorized-container">
            {
              <div className="container">
                <div className="file-count-title">
                  Requested Files{" "}
                  <span>
                    ({documentRequestCount})- {taxpayer.current ? taxpayer.name : spouse.name}
                  </span>
                </div>
                <div className="category-wrapper">
                  {printDocumentRequestList?.map((category: IDocumentRequestList, index: number) => {
                    return (
                      category.categoryId !== null && //Condition to check Unrecognized files
                      hideCategoryCondition(category) &&
                      category.documents.length > 0 && (
                        <div className="print-category-container" key={index}>
                          {category.categoryName && <div className="print-category-name">{category.categoryName}</div>}
                          <>
                            {category.documents.map((document, index) => {
                              return (
                                !document.notApplicable && ( //Condition to check NA files
                                  <div className="print-list-item" key={index}>
                                    {document.isMapped ? <RadioButtonTickedIcon /> : <RadioButtonIcon />}
                                    <span>{document.name}</span>
                                  </div>
                                )
                              );
                            })}
                          </>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            }
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default PrintableDocReqList;
