import { ButtonComponent } from "cp-common-ui-components";
import { DrlDeleteConstants } from "helper/Constants";
import React, { FC } from "react";
import { Modal } from "react-bootstrap";

interface IDeleteModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    onConfirmation: () => void;
}
const DeleteModal: FC<IDeleteModalProps> = ({ show, setShow, onConfirmation }) => {
    return (
        <Modal className="white-modal" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{DrlDeleteConstants.Title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {DrlDeleteConstants.Body}
                <br />
                {DrlDeleteConstants.Continue}
            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent variant="outline-tertiary" onClick={() => setShow(false)}>{DrlDeleteConstants.NoKeep}</ButtonComponent>
                <ButtonComponent variant="danger" onClick={onConfirmation}>{DrlDeleteConstants.YesDelete}</ButtonComponent>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteModal;