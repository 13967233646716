import { FC, useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { PrintIcon, WarnTriangleIcon } from "../../assets/svg/SVGIconsCollection";
import { FileUploadConstants, GatherUploadedDocuments, PreviewConstants } from "../../helper/Constants";
import { IDocumentRequestName } from "../../common/model/document-request-model";
import PreviewUplodedDRLFileModal from "../../common/components/file-upload/PreviewUploadedDRLFileModal";
import { getCategoryList, getDocumentRequestList, updateNotApplicableStatus,AddDRLPrintEvent } from "../../store/services/documentRequestList";
import { useAppDispatch, useAppSelector } from "../../common/hooks/redux-hooks";
import { deleteUploadedDocument, getSourceDocumentPreview } from "../../store/services/sourceDocumentUpload";
import { Toaster } from "../../common/components/Toasts";
import { isMobile } from "../../helper/HelperFunctions";
import { useClientId } from "../../common/hooks/client-hook";
import PreviewOrClientViewMode from "../common/../drl-page/PreviewOrClientViewMode";
import CollapsibleDRLList from "../common/../drl-page/CollapsibleDRLMapping";
import UnRecognizedDocumentsList from "./UnrecognizedFileMapping";
import Masonry from "react-masonry-css";
import { AppState } from "store";
import { ButtonComponent } from "cp-common-ui-components";
import PrintableDocReqList from "./PrintableDocReqList";
import { useReactToPrint } from "react-to-print";
import EditDocumentModal from "../../common/components/file-upload/EditDocumentModal";
import DeleteModal from "common/components/file-upload/DeleteModal";
export interface IDocumentRequestList {
  categoryId: number;
  categoryName: string;
  documents: {
    id: number;
    name: string;
    isMapped: boolean;
    documentId?: number;
    type?: string;
    notApplicable?: boolean;
    fileName?: string;
    sourceDocuments: {
      fileName: string;
      pageNumber: number;
      documentId: number;
    }[];
  }[];
  unRecognizedDocuments: {
    name: string;
    isMapped: boolean;
    documentId?: number;
    type?: string;
    fileName?: string;
    pageNumber?: number;
  }[];
}

const uncategorizedDocuments: { id: number; name: string }[] = [];
const MAX_COLUMNS: number = 4;
const BOOTSTRAP_GRID_COLUMNS: number = 12;

const DocumentRequestList: FC<{ isStepCompleted: boolean }> = ({ isStepCompleted }) => {
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [seletectedFileID, setSeletectedFileID] = useState<number>(0);
  const [columns, setColumns] = useState<number>(0);
  const [requestedDocumentsColumnSize, setrequestedDocumentsColumnSize] = useState<number>(BOOTSTRAP_GRID_COLUMNS);
  const [uncategorizedDocumentsColumnSize, setUncategorizedDocumentsColumnSize] = useState<number>(BOOTSTRAP_GRID_COLUMNS);
  const dispatch = useAppDispatch();
  const { documentRequestList, documentRequestNames } = useAppSelector((state) => state.documentRequestReducer);
  const { previewDocumentData } = useAppSelector((state) => state.sourceDocumentUploadReducer);
  const [clientId] = useClientId();
  const isClientView = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isClientView);
  const isPreview = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isPreview);
  const isPreviewOrClientViewMode = isClientView || isPreview;
  const documentRequestCount = documentRequestNames.filter(
    (item: IDocumentRequestName) => !!item.id && item.type !== GatherUploadedDocuments.NotAvailableFormType && !item.notApplicable
  ).length;
  const componentRef = useRef<HTMLDivElement>(null);

  const categorizedDRColBreakPoints = {
    2800: 5,
    1700: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  const uncategorizedDRColBreakPoints = {
    2800: 1,
    500: 1
  };

  useEffect(() => {
    dispatch(getCategoryList(clientId));
    dispatch(getDocumentRequestList(clientId));
  }, []);

  const getTotalUncategorizedDocuments = () => {
    return documentRequestList.reduce((acc: number, category: IDocumentRequestList) => {
      if (category.categoryId === null) {
        const sourceDocumentCount = category.documents.reduce((docAcc, document) => docAcc + document.sourceDocuments.length, 0);
        return acc + sourceDocumentCount;
      }
      return acc;
    }, 0);
  };

  const getTotalCategorizedDocuments = () => {
    return documentRequestList.reduce((acc: number, category: IDocumentRequestList) => {
      return category.categoryId !== null ? acc + category.documents.length + category.unRecognizedDocuments.length : acc;
    }, 0);
  };
  const getTotalCategorizedDocumentsForPrint = () => documentRequestList.reduce((acc: number, category: IDocumentRequestList) =>
    category.categoryId !== null ? acc + category.documents.length : acc
    , 0);
  ;

  const notApplicableClicked = (documentRequestId: Number) => {
    if (!isPreviewOrClientViewMode) {
      dispatch(
        updateNotApplicableStatus(
          clientId,
          {
            gatherDocumentRequestId: documentRequestId,
            notApplicable: true
          },
          () => {
            dispatch(getCategoryList(clientId));
            dispatch(getDocumentRequestList(clientId));
          }
        )
      );
    }
  };

  const undoNotApplicableClicked = (documentRequestId: Number) => {
    if (!isPreviewOrClientViewMode) {
      dispatch(
        updateNotApplicableStatus(
          clientId,
          {
            gatherDocumentRequestId: documentRequestId,
            notApplicable: false
          },
          () => {
            dispatch(getCategoryList(clientId));
            dispatch(getDocumentRequestList(clientId));
          }
        )
      );
    }
  };

  useEffect(() => {
    calculateColumns();
  }, [documentRequestList]);

  const handlePrint = useReactToPrint({
      contentRef: componentRef
    });

  const displayDocumentFormType = (document: any): string => {
    return `${document?.type !== GatherUploadedDocuments.NotAvailableFormType
      ? document.type == null
        ? ""
        : " (" + document.type.replace("Form ", "") + ")"
      : ""
      }`;
  };

  const onPreviewButtonClick = (document: any, categoryName?: string) => {
    setSelectedFile({ ...document, categoryName });
    dispatch(getSourceDocumentPreview(clientId, document.documentId));
    setShowPreviewModal(true);
  };

  const onEditButtonClick = (document: any, categoryId: number) => {
    setShowEditPopup(true);
    setSelectedFile({ ...document, categoryId });
  };

  const onDeleteButtonClick = (id: number, isConfirmation?: boolean) => {
    let mappedCount = 0;
    documentRequestList.forEach((category: IDocumentRequestList) => {
      category.documents.forEach((document) => {
        document.sourceDocuments.forEach((sourceDocument) => {
          if (sourceDocument.documentId === id) {
            mappedCount++;
          }
        });
      });
    });
    if (!isConfirmation && mappedCount > 1) {
      setShowDeletePopup(true);
      setSeletectedFileID(id);
    } else {
      dispatch(
        deleteUploadedDocument(
          clientId,
          id,
          () => {
            dispatch(getDocumentRequestList(clientId));
            Toaster.success(GatherUploadedDocuments.DocumentDeleted);
            dispatch(getCategoryList(clientId));
            isConfirmation && setShowDeletePopup(false);
          },
          (errorDescription) => {
            Toaster.error(errorDescription);
          }
        )
      );
    }
  };

  const calculateColumns = () => {
    // function to set dynamic columns for requested files, based on available documents and screen size
    let div = document.getElementById("drl-list-container");
    let columns = 1;
    let requestedDocumentsColumnSize = BOOTSTRAP_GRID_COLUMNS;
    if (div) {
      let totalDocuments = documentRequestList.length || 0;
      let listItem = document.getElementById("category-list-item");
      let listItemHeight = listItem?.clientHeight || 0;
      let maxVisibleRows = Math.ceil((div.clientHeight - 75) / listItemHeight);
      let columnDivisor = 1;

      if (totalDocuments > 0 && maxVisibleRows > 0) {
        columns = Math.ceil(totalDocuments / maxVisibleRows);
        columns = columns > MAX_COLUMNS ? MAX_COLUMNS : columns;
        columnDivisor = columns < MAX_COLUMNS ? columns : MAX_COLUMNS - 1;
        requestedDocumentsColumnSize =
          uncategorizedDocuments?.length > 0
            ? BOOTSTRAP_GRID_COLUMNS - BOOTSTRAP_GRID_COLUMNS / (columnDivisor + 1)
            : BOOTSTRAP_GRID_COLUMNS;
      } else {
        columns = 1;
        requestedDocumentsColumnSize = 0;
      }
    }
    if (isMobile()) {
      setrequestedDocumentsColumnSize(BOOTSTRAP_GRID_COLUMNS);
      setUncategorizedDocumentsColumnSize(BOOTSTRAP_GRID_COLUMNS);
      setColumns(1);
    } else {
      setrequestedDocumentsColumnSize(requestedDocumentsColumnSize);
      setUncategorizedDocumentsColumnSize(
        documentRequestList?.length > 0 ? BOOTSTRAP_GRID_COLUMNS - requestedDocumentsColumnSize : BOOTSTRAP_GRID_COLUMNS
      );
      setColumns(columns);
    }
  };
  const renderPrintButton = () => {
    if (!isMobile()) {
      if (documentRequestCount !== 0 && getTotalCategorizedDocumentsForPrint() > 0) {
        return (
          <div className="action-btn-wrapper">
            <ButtonComponent
              disbaled={isPreviewOrClientViewMode}
              onClick={() => {
              if (!isPreviewOrClientViewMode) {
                dispatch(AddDRLPrintEvent(clientId));
                handlePrint();
              }
              }}
              variant="link"
              buttonClassName={`print-button ${isPreviewOrClientViewMode ? "disabled" : " "}`}
              title={isPreviewOrClientViewMode ? PreviewConstants.PrintNotAllowed : "Click to print"}
            >
              <PrintIcon />
              Print
            </ButtonComponent>
          </div>
        );
      }
    } else {
      <></>;
    }
  };

  const getDynamicHeightId = () => {
    if (!isMobile()) {
      return getTotalCategorizedDocuments() ? "height-with-print-btn" : "";
    }
    return "";
  };
  return (
    <div className="list-container" id="drl-list-container">
      <Row>
        <Col xs={12}>
          <div className="tag">
            <div className="margin-bottom-5-px margin-left-5-px">
              <WarnTriangleIcon />
            </div>
            <span className="margin-left-10-px">
              <b>Important!</b> Encrypted, password-protected, or locked files are not supported.
            </span>
          </div>
        </Col>
        {!getTotalCategorizedDocuments() && !getTotalUncategorizedDocuments() ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div>
              <b>No Files uploaded yet...</b>
            </div>
            <div>Please upload your files</div>
          </div>
        ) : (
          <></>
        )}
        {renderPrintButton()}
        {getTotalCategorizedDocuments() ? (
          <Col
            className={`categorized-container`}
            id={getDynamicHeightId()}
            xs={isMobile() || !getTotalUncategorizedDocuments() ? 12 : 9}
          >
            <div className="title">
              Requested Files <span>({documentRequestCount})</span>
            </div>

            {
              /* Masonry library can be removed in future if there is package size concern 
              and can be replaced with custom css approach to display the documents in a grid format without vertical while spaces using 
              float and random height approach algorithm
              */
              /* Below is the approach to display the documents in a grid format without vertical while spaces*/
              /** React Masonry Css approach */
              <Masonry
                breakpointCols={categorizedDRColBreakPoints}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {documentRequestList?.map((category: IDocumentRequestList, index: number) => {
                  return (
                    category.categoryId !== null &&
                    (category.documents.length > 0 || category.unRecognizedDocuments.length > 0) && (
                      <div className="category-container" key={index}>
                        {category.categoryName && category.categoryName !== FileUploadConstants.AdditionalDRL && (
                          <div className="category-name ellipsis" title={category.categoryName}>
                            {category.categoryName}
                          </div>
                        )}
                        <div>
                          {category.documents.map((document: any, index: number) => {
                            return (
                              <CollapsibleDRLList
                                key={index}
                                id={document.id}
                                title={document.name + displayDocumentFormType(document)}
                                documentsList={document.sourceDocuments}
                                isMapped={document.isMapped}
                                notApplicable={document.notApplicable}
                                category={category}
                                onDeleteButtonClick={onDeleteButtonClick}
                                isPreviewOrClientViewMode={isPreviewOrClientViewMode}
                                onEditButtonClick={onEditButtonClick}
                                onPreviewButtonClick={onPreviewButtonClick}
                                isStepCompleted={isStepCompleted}
                                undoNotApplicableClicked={undoNotApplicableClicked}
                                notApplicableClicked={notApplicableClicked}
                              />
                            );
                          })}
                          {/* Other recognized files */}
                          <UnRecognizedDocumentsList
                            documentsList={category.unRecognizedDocuments}
                            category={category}
                            onDeleteButtonClick={onDeleteButtonClick}
                            isPreviewOrClientViewMode={isPreviewOrClientViewMode}
                            onEditButtonClick={onEditButtonClick}
                            onPreviewButtonClick={onPreviewButtonClick}
                            isStepCompleted={isStepCompleted}
                          />
                        </div>
                      </div>
                    )
                  );
                })}
              </Masonry>
            }
          </Col>
        ) : (
          <></>
        )}
        {getTotalUncategorizedDocuments() ? (
          <Col
            className={`uncategorized-container`}
            id={getDynamicHeightId()}
            xs={isMobile() || !getTotalCategorizedDocuments() ? 12 : 3}
          >
            <div className="title">Unrecognized Files({getTotalUncategorizedDocuments()})</div>
            <Masonry
              breakpointCols={uncategorizedDRColBreakPoints}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {documentRequestList.map((category: IDocumentRequestList, index: number) => {
                if (category.categoryId === null && category.documents.length > 0) {
                  return category.documents.map((document) =>
                    document.sourceDocuments.map((sourceDoc, sourceDocIndex) => (
                      <div key={sourceDocIndex} className="list-item unCategorized-list-item">
                        <span className="ellipsis" title={sourceDoc.fileName}>
                          {sourceDoc.fileName}
                        </span>
                        <PreviewOrClientViewMode
                          isHoveredIconsVisible={!!sourceDoc.fileName}
                          document={sourceDoc}
                          category={category}
                          onDeleteButtonClick={onDeleteButtonClick}
                          isPreviewOrClientViewMode={isPreviewOrClientViewMode}
                          onEditButtonClick={onEditButtonClick}
                          onPreviewButtonClick={onPreviewButtonClick}
                          isStepCompleted={isStepCompleted}
                        />
                      </div>
                    ))
                  );
                }
                return null;
              })}
            </Masonry>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <PreviewUplodedDRLFileModal
        customClass=""
        documentType={selectedFile?.categoryName}
        fileName={selectedFile?.fileName ?? ""}
        fileId={selectedFile?.documentId ?? 0}
        fileType={previewDocumentData.extension.slice(1)}
        onHidePDFPreview={() => setShowPreviewModal(false)}
        showPDFPreview={showPreviewModal}
        documentId={selectedFile?.documentId ?? 0}
        url={previewDocumentData.path}
        isStepCompleted={isStepCompleted}
      />
      {showEditPopup && <EditDocumentModal show={showEditPopup} onHide={() => setShowEditPopup(false)} selectedDocument={selectedFile} />}
      <div ref={componentRef} className="printable-area">
        <PrintableDocReqList />
      </div>
      <DeleteModal show={showDeletePopup} setShow={setShowDeletePopup} onConfirmation={() => onDeleteButtonClick(seletectedFileID, true)} />
    </div>
  );
};

export default DocumentRequestList;
